import auth from './auth';
import form from './form';
import contract from './contract';
import employee from './employee';
import vehicle from './vehicle';
import remolque from './remolque';
import turno from './turno';
import {createStore} from 'vuex';
 
const store = createStore({
    modules:{
        auth: auth,
        form: form,
        contract: contract,
        employee: employee,
        vehicle: vehicle,
        turno: turno,
        remolque:remolque,
    }
});
 
export default store;