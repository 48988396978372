<template id="login">
  <div class="flex flex-column align-items-center justify-content-center min-height-100">
    <div class="card m-5 flex flex-column align-items-center">
      <img src="@/assets/logo.svg" alt="icono corporativo" class="logo-image">
      <h5>CONDUCTOR</h5>
      <div class="flex flex-column align-items-center mt-3 w-full">
        <label for="username2">Nombre de usuario</label>
        <InputText id="username2" type="username" aria-describedby="username2-help" :class="{'error': error.credentials}" v-model="username" />
        <small v-if="error.credentials" id="username2-help" class="error-message">Usuario inválido.</small>
      </div>
      <div class="flex flex-column align-items-center w-full mt-4">
        <label for="password">Contraseña</label>
        <PasswordPrime v-model="password" toggleMask />
      </div>
      <ButtonPrime class="p-button-raised p-button-rounded large-button mt-3" icon="pi pi-users" label="Ingresar" @click.prevent="logIn()" />
    </div>
  </div>
</template>



<script>
    import {mapGetters} from 'vuex';
    import AuthService from '@/services/AuthService';

    let LoginPage = {};
    (function () {
        this.name = 'login-page';
        // let _self = null;

        this.created = function () {
            this.$store.dispatch('form/error', {credentials: false});
        };

        this.mounted  = function () {

        };

        this.data = function () {
            return {
                username: '',
                password: ''
            }
        };
        this.methods = {
             isInGroups: function (groupName) {
                for (var i = 0; i < this.groups.length; i++) {
                        if (this.groups[i].name === groupName) {
                            return true;
                        }  
                }
                return false;
            },
            logIn() {
                this.$store.dispatch('form/reset');
                this.$store.dispatch('form/submit', true)
                this.$store.dispatch('form/error', {
                    username: false,
                    password: false,
                    credentials: false
                });
                
                if (this.username === null || this.username.trim() === '') {
                    this.$store.dispatch('form/error', {username: true});
                }
                
                if (this.password === null || this.password.trim() === '') {
                    this.$store.dispatch('form/error', {password: true});
                }
            
                if (this.$store.getters['form/invalid-fields'].length > 0) {
                    this.$store.dispatch('form/submit', false)
                    return
                }
               
                this.$store.dispatch('auth/logout');
               
                AuthService.log_in(this.username, this.password)
                    .then(response => {
                      this.$store.dispatch('auth/set-access-token', response.data.access_token);
                      this.$store.dispatch('auth/set-refresh-token', response.data.refresh_token);
                       AuthService.getUserDetail(this.username, this.password)
                            .then(response2 => {

                                this.$store.dispatch('auth/set-user', response2[1].employee_id);
                                this.$store.dispatch('auth/set-groups', response2[0]);

                                if(this.isInGroups('driver') === false) {
                                    let result = {isError: true, message: 'Accedo denegado'};
                                        this.$store.dispatch('form/result', result);
                                        this.$router.push({name: 'logout'});
                                        this.$store.dispatch('form/submit', false);
                                }else{
                                    this.$router.replace({name: 'select-turno'}); 
                                    this.$store.dispatch('form/submit', false);
                                }


                               
                            }).catch((error) => {
                                console.log(error);
                                this.$store.dispatch('form/submit', false);
                            });
                    })
                    .catch(err => {
                      if (err.response.status === 400) {
                        this.$store.dispatch('form/error', {credentials: true});
                        this.$store.dispatch('form/submit', false);
                      }
                    });
            }
        };
        this.computed = {
            ...mapGetters({
                error: 'form/error-states',
                groups: 'auth/groups'
            }),
        };
        this.watch = {
            username(newValue){
                this.username = newValue.toLowerCase();
            }
        };
        this.props = [];
    }).apply(LoginPage);

    export default LoginPage
</script>

<style scoped>
.min-height-100 {
  min-height: 100vh;
}

.w-full {
  width: 100%;
}

.logo-image {
  width: 80px;
}

.error-message {
  color: var(--error-color);
  text-align: center;
}

</style>
