const state = () => ({
	turno: localStorage.getItem('turno'),
});

const getters = {
	turno(state) {
        return state.turno;
    },
};

const actions = {
	'set-turno'(context, turno) {
        context.commit('updateTurno', turno);
        localStorage.setItem('turno', turno);
    },
};

const mutations = {
	updateTurno(state, value) {
		state.turno = value;
	},
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
