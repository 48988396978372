<template>
  <div class="p-fluid">
    <p class="text-center">Selecciones Contrato </p>

    <DropdownPrime v-model="selectedContract" :options="contracts" optionLabel="name" placeholder="Seleccione contrato" class="dropdown" />

    <p class="text-center">Selecciones Turno </p>


      <span  v-for="shift in shifts" :key="shift.id" >
        <ButtonPrime
        :label="formattedShifts(shift)"
        @click="selectShift(shift)"
        class="button shift-button"
      />
      <br /> <br />
      </span>
       
      <p v-if="shifts.length == 0 && selectedContract" class="text-center">Sin Turno, comuníquese con su supervidor</p>


    <!--ButtonPrime label="Siguiente" icon="pi pi-check" @click="siguiente" :disabled="!selectedContract && !selectedShift" class="button" /-->
  </div>
</template>

<script>

    import ContractService from '@/services/ContractService';
    import WorkShiftService from '@/services/WorkShiftService';
    import {mapGetters} from 'vuex';
    import AutoComplete from 'primevue/autocomplete';
    import Dropdown from 'primevue/dropdown';

    import moment from "moment";

    let NewTripage = {};
    (function () {
        this.name = 'new-trip';
        let _self = null;

        this.created = function () {
            _self = this;
            this.$store.dispatch('form/submit', true);
            this.getAllContracts();
        };

        this.mounted = function () {
            window.scrollTo(0, 0);
        };

        this.data = function () {
            return {

                staticRoot: process.env.VUE_APP_STATIC,

                contracts:[],
                selectedContract: null,

                shifts:[],
                selectedShift: null,

            }
        };
        this.components = {AutoComplete, Dropdown};
        this.computed = {
           shouldHideDropdowns() {
                  return this.all_origins.length === 1 
                      && this.all_returns.length === 1 
                      && this.all_destination.length === 1 
                      && this.all_rute.length === 1;
              },
          ...mapGetters({
                username: 'auth/user',
                error: 'form/error-states',
            }),
        };
        this.methods = {
                     formattedShifts(s) {
              return s.work_shift_type + ' ' + this.formatDate(s.work_shift_start);
            },
         selectShift(s){
            this.selectedShift = s;
            this.siguiente();
         },
          formatDate(dateString) {
            const date = new Date(dateString);
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Añadir 1 porque getMonth() devuelve un índice basado en cero
            const year = date.getFullYear();
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');


            return `${day}/${month}/${year} ${hours}:${minutes}`;
          },
getWorkShiftRange2(contract) {
  this.shifts = [];
  this.$store.dispatch('form/submit', true);

  const currentTime = moment();
  let startDate;

  // Determine if the current time is between 00:00 and 07:59
  if (currentTime.hour() >= 0 && currentTime.hour() < 8) {
    // If it is, set startDate to yesterday
    startDate = currentTime.subtract(1, 'days').format("YYYY-MM-DD");
  } else {
    // Otherwise, set startDate to today
    startDate = currentTime.format("YYYY-MM-DD");
  }

  const endDate = currentTime.add(1, 'days').format("YYYY-MM-DD"); // Ensuring we cover all shifts for the day

  WorkShiftService.getWorkShiftRangeDates(contract.contract_id, [startDate, endDate]).then(response => {
    // Filter to keep only the shifts starting within the designated time range for "today"
    this.shifts = response.data.filter(shift => {
      const shiftDate = moment(shift.work_shift_start);
      return shiftDate.isSame(startDate, 'day') && (shiftDate.hour() >= 8 || shiftDate.hour() < 1);
    });

    this.showAddShiftButton = true;
    this.$store.dispatch('form/submit', false);
  }).catch(error => {
    console.log(error);
    this.$store.dispatch('form/submit', false);
  });
},

          replaceTZ(date){
            if(date){
              var stillUtc = moment.utc(date).toDate();
              var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm');
              return local;
            }
          },
            
          getAllContracts(){
                this.$store.dispatch('form/submit', true);
             ContractService.getByEmployee(this.username).then((response) => {
                        this.contracts = response.data;
                        console.log(this.contracts.length)
                        if(this.contracts.length == 1){
                          this.selectedContract = this.contracts[0];
                        }
                        this.$store.dispatch('form/submit', false);
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
          },
           getNumbersDots: function (num) {
              return num.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
            },
  
            siguiente(){

                this.$store.dispatch('form/reset');
                this.$store.dispatch('form/submit', true);
                

            let result = {
                isError: false,
                message: null
            }
 

            if (this.username === null) {
                result.isError = true;
                result.message = 'Seleccione Conductor, reinicie aplicacion'
                _self.$store.dispatch('form/result', result);
                _self.$store.dispatch('form/submit', false);
                return;
            }

            if (this.selectedShift === null) {
                result.isError = true;
                result.message = 'Turno no seleccionado'
                _self.$store.dispatch('form/result', result);
                _self.$store.dispatch('form/submit', false);
                return;
            }

             if (this.selectedContract === null) {
                result.isError = true;
                result.message = 'Contrato no seleccionado'
                _self.$store.dispatch('form/result', result);
                _self.$store.dispatch('form/submit', false);
                return;
            }

            this.$store.dispatch('contract/set-contract', this.selectedContract);
            this.$store.dispatch('turno/set-turno', this.selectedShift);
            
            this.goTo('select-vehicle');

            },
            goTo: function (route) {
                this.$router.push({name: route});
            },
        };
        this.watch = {
          selectedContract(newValue, oldValue) {
            console.log(oldValue);
            if (newValue) {
              this.getWorkShiftRange2(newValue); 

            }
          }
        };

          
        
    }).apply(NewTripage);

    export default NewTripage
</script>

<style scoped>
    .is-day{
        box-shadow: 0 0.5em 1em -0.125em rgba(251, 255, 73, 0.7), 0 0px 0 1px rgba(251, 255, 73, 0.1);
    }

    .is-night{
        box-shadow: 0 0.5em 1em -0.125em rgba(0, 0, 0, 0.7), 0 0px 0 1px rgba(0, 0, 0, 0.1);
    }

    .is-selected{
        box-shadow: 0 0.5em 1em -0.125em rgba(251, 255, 73, 0.7), 0 0px 0 1px rgba(251, 255, 73, 0.1);
    }

.p-fluid {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.text-center {
  text-align: center;
  font-size: 24px; /* Tamaño de fuente más grande para los títulos */
}

.dropdown, .inputtext, .button {
  font-size: 20px; /* Tamaño de fuente más grande para textos dentro de los inputs y botones */
  height: 50px; /* Altura mayor para inputs y botones */
  margin-bottom: 0.25rem; /* Más espacio entre elementos */
}

.inputtext {
  padding: 10px; /* Mayor padding dentro de los inputs para aumentar la legibilidad */
}

.button {
 padding: 0.5rem; /* Padding más grande para botones */
}

.dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 10px; /* Mayor padding para los items del dropdown */
  font-size: 18px; /* Tamaño de fuente más grande para los items dentro de los dropdowns */
}

.p-dropdown-label:not(.p-placeholder), .p-inputtext {
  font-size: 20px; /* Ajustar tamaño de fuente para los textos dentro de los dropdowns y inputs */
}

.p-dropdown .p-dropdown-panel {
  font-size: 18px; /* Tamaño de fuente para el panel de selección */
}

.flex-container {
  display: flex;
  align-items: center;
}

.flex-item-grow {
  flex-grow: 1;
}

.p-button-help {
  padding: 0.5rem; /* Reducir el padding para que el botón no sea muy grande */
  width: 2rem; /* Ajustar el ancho mínimo para que el botón sea más pequeño */
}

.error-border {
  border: 1px solid red;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}


</style>
