<template>
  <div class="home mt-6" style="margin-top: 150px !important;">
    <div class="button-group">
      <ButtonPrime label="Nuevo Viaje" @click="newTrip" class="p-button-raised p-button-rounded large-button" />
      <ButtonPrime label="Viajes" @click="goTrips" class="p-button-raised p-button-rounded large-button" />
      <ButtonPrime label="Salir/Kilometraje" @click="updateMileage" class="p-button-raised p-button-rounded large-button" />
    </div>

    <div class="button-group mt-2" v-if="documents.length && count_count == 4">
      <div v-for="document in documents" :key="document.document_id">
        <ButtonPrime 
          :label="document.name" 
          @click="openImageModal(document)" 
          :class="getButtonClass(document.status)" 
          class="p-button-raised p-button-rounded large-button">
          <span v-if="fatiga_id == document.document_id || covid_id == document.document_id || document.document_id == checklist_id || document.document_id == det_id">
            {{ answersCount[document.document_id] !== undefined ? `${answersCount[document.document_id]} / ${document.total_questions}` : 'Cargando...' }}
          </span>
        </ButtonPrime>
      </div>
    </div>

    <DialogPrime v-model:visible="imageModalVisible" modal header="">
      <div class="p-fluid" v-if="selectedDocument">

        <p  v-if="!selectedDocument" >Ingreso exitoso</p>

        <p  v-if="selectedDocument" >{{selectedDocument.name}}</p>

        <h3  v-if="fatiga_id != selectedDocument.document_id && covid_id != selectedDocument.document_id && checklist_id != selectedDocument.document_id && det_id != selectedDocument.document_id" > Adjunte Imagen</h3>
         <h3  v-if="fatiga_id == selectedDocument.document_id || covid_id == selectedDocument.document_id || checklist_id == selectedDocument.document_id || det_id == selectedDocument.document_id" > Complete cuestionario</h3>
        <p v-if="getObservation != '' && getObservation != null">Observacion: {{getObservation}}</p>

        <p  v-if="fatiga_id != selectedDocument.document_id && covid_id != selectedDocument.document_id && checklist_id != selectedDocument.document_id && det_id != selectedDocument.document_id" >Estado: {{getValid}}</p>


        <span v-if="fatiga_id != selectedDocument.document_id && covid_id != selectedDocument.document_id && checklist_id != selectedDocument.document_id && det_id != selectedDocument.document_id">
          <img v-if="current_img != '' && current_img" class="q-mt-md" :src="st + current_img" style="width: 50%; heigth: auto;"/>
          <br />

          <input type="file" ref="fileInput" @change="handleFileSelect">
          <br />

          <img v-if="current_img_2 != '' && current_img_2 && selectedDocument?.two_page" class="q-mt-md" :src="st + current_img_2" style="width: 50%; heigth: auto;"/>
          <br />
          <input type="file" ref="fileInput2" @change="handleFileSelect2" v-if="selectedDocument?.two_page">
          <br />
        </span>

        <ButtonPrime v-if="fatiga_id == selectedDocument.document_id || covid_id == selectedDocument.document_id || checklist_id == selectedDocument.document_id || det_id == selectedDocument.document_id" label="Ir Cuestionario" @click="irCuestionario" class="p-button-raised p-button-rounded" />
        <ButtonPrime  v-if="fatiga_id != selectedDocument.document_id && covid_id != selectedDocument.document_id && checklist_id != selectedDocument.document_id && det_id != selectedDocument.document_id" label="Subir Imagen Documentos" @click="uploadImage" class="p-button-raised p-button-rounded" />

      </div>
      <div class="p-fluid" v-else>
        Ingreso exitoso
      </div>
    </DialogPrime>
  </div>
</template>



<script>
import ShiftDocumentService from '@/services/ShiftDocumentService';
import UploadService from '@/services/UploadService';
import imageCompression from 'browser-image-compression';
import { mapGetters } from 'vuex';
import { getTotalAnswers } from '@/services/QuestionsService';

export default {
  name: 'HomeView',
  components: {},
  data() {
    return {
      imageModalVisible: false,
      selectedDocument: null,
      documents: [],
      documents_driver: [],
      selectedFile: null,
      selectedFile2: null,
      current_img: null,
      current_img_2: null,
      valid: null,
      observation: '',
      current_doc_id: null,
      st: process.env.VUE_APP_STATIC,
      checklist_id: process.env.VUE_APP_CHECKLIST_ID,
      det_id: process.env.VUE_APP_DET_ID,
      fatiga_id: process.env.VUE_APP_FATIGA_ID,
      covid_id: process.env.VUE_APP_COVID_ID,

        answersCount: {},
        count_count: 0,
    };
  },
  methods: {

     async fetchAnswersCount() {
      console.log('aqui');
      const counts = {};
      for (const document of this.documents) {

        if (document.document_id == this.fatiga_id || document.document_id == this.covid_id || document.document_id == this.checklist_id || document.document_id == this.det_id) {
          try {
            const response = await getTotalAnswers(
              this.username,
              this.vehicle.vehicle_id,
              this.tray.vehicle_id,
              this.turno.water_work_shift_id,
              document.document_id
            );
            console.log(response);
            counts[document.document_id] = document.name + ' - ' + response.total_answers; 
            this.count_count = this.count_count + 1;
          } catch (error) {
            console.error('Failed to fetch total answers:', error);
            counts[document.document_id] = 0;
          }
        }
      }
      this.answersCount = counts; // Actualiza el estado con los conteos
    },
    irCuestionario() {
    console.log('documentTypeId:', this.selectedDocument.document_id);
    console.log('driverId:', this.username);
    console.log('vehicleId:', this.vehicle.vehicle_id);
    console.log('trayId:', this.tray.vehicle_id);
    console.log('shiftId:', this.turno.water_work_shift_id);
     console.log('driverdocumentid:', this.current_doc_id);

    if (
      this.selectedDocument.document_id &&
      this.username &&
      this.vehicle.vehicle_id &&
      this.tray.vehicle_id &&
      this.turno.water_work_shift_id
    ) {
      this.$router.push({
        name: 'questionario',
        params: {
          documentTypeId: this.selectedDocument.document_id,
          driverId: this.username,
          vehicleId: this.vehicle.vehicle_id,
          trayId: this.tray.vehicle_id,
          shiftId: this.turno.water_work_shift_id,
          driverdocumentid: this.current_doc_id
        }
      });
    } else {
      console.error('Faltan parámetros requeridos');
    }
  },
    getValidState(valid){
      if(valid)
        return 'Valido';
      if(valid == false)
        return 'Rechazado';
      if(valid == null)
        return 'Sin Documento'
    },
    goTrips(){
      this.$router.push({ name: 'my-trips' });
    },
    fetchDriverDocuments() {
      ShiftDocumentService.getDriverDocumentsByShift(this.turno.water_work_shift_id, this.vehicle.vehicle_id, this.tray.vehicle_id, this.username)
        .then(response => {
          this.documents_driver = response.data;
          this.updateDocumentStatus();
        })
        .catch(error => {
          console.error('Error fetching documents:', error);
        });
    },

    newTrip() {
      this.$router.push({ name: 'new-trip' });
    },
    updateMileage() {
      this.$router.push({ name: 'update-km' });
    },
    listDocuments() {
      ShiftDocumentService.getAll()
        .then(response => {
          this.documents = response.data.data;
          this.fetchDriverDocuments()
          this.fetchAnswersCount()
        })
        .catch(error => {
          console.error('Error al listar los documentos:', error);
        });
    },
    openImageModal(document) {
      this.selectedDocument = document;
      this.createShiftDocument();
    },
    createShiftDocument() {
 this.$store.dispatch('form/submit', true)

      const data = {
        driver_id: this.username,
        vehicle_id: this.vehicle.vehicle_id,
        tray_id: this.tray.vehicle_id,
        shift_id: this.turno.water_work_shift_id,
        document_type_id: this.selectedDocument.document_id
      };

      ShiftDocumentService.create(data)
        .then(response => {
          this.imageModalVisible = true;
          this.current_doc_id = response.data.driver_document_id;
          console.log();
          this.current_img = response.data.url_img;
          this.current_img_2 = response.data.url_img_2;
          this.observation = response.data.observation;
          this.valid = response.data.valid;
           this.$store.dispatch('form/submit', false)
        })
        .catch(error => {
          console.error('Error al crear el documento de turno:', error);
           this.$store.dispatch('form/submit', false)
        });
    },
    handleFileSelect(event) {
      this.selectedFile = event.target.files[0];
    },
    handleFileSelect2(event) {
      this.selectedFile2 = event.target.files[0];
    },
    async uploadImage() {
       this.$store.dispatch('form/submit', true)
      if (!this.selectedFile && !this.selectedFile2) {
        console.error('No se ha seleccionado ningún archivo.');
        return;
      }

      if (this.selectedFile) {
        if (!this.selectedFile.type.startsWith('image/')) {
          console.error('El archivo seleccionado no es una imagen.');
          return;
        }
        await this.uploadFile(this.selectedFile, 'compressed-image.jpg');
      }

      if (this.selectedFile2 && this.selectedDocument.two_page) {
        if (!this.selectedFile2.type.startsWith('image/')) {
          console.error('El archivo seleccionado no es una imagen.');
          return;
        }
        await this.uploadFile(this.selectedFile2, 'compressed-image-2.jpg', 'url_2');
      }

      console.log('Simulación: Documento subido exitosamente.');
      
       this.$store.dispatch('form/submit', false)
    },
    async uploadFile(file, fileName, urlField = 'url') {
      const formData = new FormData();
      formData.append('docs', file);
      formData.append('driver_document_id', this.current_doc_id);

      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(file, options);
        formData.set('docs', compressedFile, fileName);
      } catch (error) {
        console.error('Error al comprimir la imagen:', error);
      }

      formData.append('destination', 'drivers-shift-document/');
      formData.append('urlField', urlField);

      try {
        const response = await UploadService.uploadfileShiftDocument(formData);
        console.log('Documento subido exitosamente:', response.data);
        if(!this.selectedDocument.two_page){
           this.selectedDocument = null;
           this.current_doc_id = null;   
        }
        this.finalizeUpload();
      } catch (error) {
        console.error('Error al subir el documento:', error);
      }
    },
    finalizeUpload() {
      this.fetchDriverDocuments();
    },
    getButtonClass(status) {
      switch (status) {
        case 'Pendiente':
          return 'button-pending';
        case 'Valido':
          return 'button-accepted';
        case 'Rechazado':
          return 'button-rejected';
        case 'SIN':
          return 'button-no-status';
        default:
          return 'button-no-status';
      }
    },
    updateDocumentStatus() {
      this.documents.forEach(doc => {
        const driverDoc = this.documents_driver.find(d => d.document_type_id_id === doc.document_id);
        if (driverDoc) {
          doc.status = driverDoc.status;
        } else {
          doc.status = 'Sin';
        }
      });
    }
  },
  mounted() {
    this.listDocuments();
    console.log('Fatiga ID:', this.fatiga_id);
  console.log('Covid ID:', this.covid_id);
  },
  computed: {
    getObservation(){
      return this.observation;
    },
    getValid(){
      return this.getValidState(this.valid);
    },
    ...mapGetters({
      username: 'auth/user',
      error: 'form/error-states',
      turno: 'turno/turno',
      contract: 'contract/contract',
      vehicle: 'vehicle/vehicle',
      tray: 'vehicle/tray',
    }),
  }
};
</script>


<style scoped>
.home .button-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.large-button {
  min-width: 200px;
  padding: 10px 20px;
}

.button-pending {
  background-color: yellow;
  color: black;
}

.button-accepted {
  background-color: green;
  color: white;
}

.button-rejected {
  background-color: red;
  color: white;
}

.button-no-status {
  background-color: gray;
  color: white;
}
</style>



