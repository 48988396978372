import axios from 'axios';

export function getQuestionsByCategory(documentTypeId, driverId, vehicleId, trayId, shiftId) {

  return axios.get(`${process.env.VUE_APP_API_URL}/document-questions/${documentTypeId}/${driverId}/${vehicleId}/${trayId}/${shiftId}/`);
}

export function submitAnswers(data) {
  return axios.post(`${process.env.VUE_APP_API_URL}/document-submit-answers/`, data);
}

export const getTotalAnswers = async (driverId, vehicleId, trayId, shiftId, documentTypeIds) => {
    try {
      const response = await axios.post(`${process.env.VUE_APP_API_URL}/document-total-answers/` , {
        driver_id: driverId,
        vehicle_id: vehicleId,
        tray_id: trayId,
        shift_id: shiftId,
        document_type_ids: documentTypeIds
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching total answers:', error);
      throw error;
    }
  }

  export function finalizarCuestionario(data) {
    return axios.post(`${process.env.VUE_APP_API_URL}/verify-quiz/`, data);
  }
