const state = () => ({
	vehicle: localStorage.getItem('vehicle'),
    tray: localStorage.getItem('tray'),
});
 
const getters = {
	vehicle(state) {
        return state.vehicle
    },
    tray(state) {
        return state.tray
    },
};
 
const actions = {
	'set-vehicle'(context, vehicle) {
        context.commit('vehicle', vehicle);
        localStorage.setItem('vehicle', vehicle);
    },
    'set-tray'(context, tray) {
        context.commit('tray', tray);
        localStorage.setItem('tray', tray);
    },
};
 
const mutations = {
    tray(state, value) {
		state.tray = value
	},
	vehicle(state, value) {
		state.vehicle = value
	},
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}