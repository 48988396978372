<template>
    <span class="toolbar-wrapper">

        <ToolBar class=" toolbar fixed top-0 ml-0 w-12"  >
            <template #start>
                <div class="logo-container"><img class="logo" alt="icono corporativo" src="@/assets/logo.svg"></div>
            </template>
            <template #end>
                <ButtonPrime class="toolbar-button" icon="pi pi-bars" @click.prevent="openSideBar()" />
            </template>
        </ToolBar>

        <SidebarPrime v-model:visible="visibleRight" position="right">
            <MenuPrime :model="items" @click="handleMenuClick()" style="width: 90%;"/>
        </SidebarPrime>
    </span>
</template>

<script>
export default {
    name: 'ToolbarComp',
    data() {
        return {
            visibleRight: false,
            items: [
                {
                    label: 'Conductor',
                    items: [
                        {
                            label: 'Seleccione turno',
                            icon: 'pi pi-fw pi-plus',
                            command: () => { this.$router.push({ name: 'select-turno' }) }
                        }
                    ]
                },
                {
                    label: 'Sistema',
                    items: [
                        {
                            label: 'Salir',
                            icon: 'pi pi-fw pi-power-off',
                            command: () => { this.$router.push({ name: 'logout' }) }
                        }
                    ]
                }
            ]
        };
    },
    methods: {
        handleMenuClick() {
            this.visibleRight = !this.visibleRight;
        },
        openSideBar() {
            this.visibleRight = true;
        },
        goTo(route) {
            this.$router.push({ name: route });
        }
    },
};
</script>

<style scoped>
.toolbar-wrapper {
    position: relative;
}

.toolbar {
    height: 50px; /* Ajusta la altura del ToolBar */
    width: 100%;
    padding: 0 0.5rem; /* Ajusta el padding según sea necesario */
    box-sizing: border-box;
}

.logo-container {
    width: 50px; /* Ajusta el tamaño del contenedor del logo */
}

.logo {
    width: 20px; /* Ajusta el tamaño del logo */
}

.toolbar-button {
    font-size: 1rem; /* Ajusta el tamaño del ícono del botón */
    padding: 0.25rem; /* Ajusta el padding del botón */
}

.pi-bars {
    font-size: 1.5rem; /* Ajusta el tamaño del ícono de la barra */
}
</style>
