import { createRouter, createWebHistory } from 'vue-router'
import LoginPage from '../views/LoginPage.vue'
import IntroPage from '../views/IntroPage.vue'
import HomeView from '../views/HomeView.vue'
import NewTrip from '../views/NewTrip.vue'
import MyTrips from '../views/MyTrips.vue'
import UpdateKm from '../views/UpdateKilometersVehicle.vue'
import CompleteTripFormTicket from '../views/CompleteTripFormTicket.vue'
import SelectTurno from '../views/SelectTurno.vue'
import SelectVehicle from '../views/SelectVehicle.vue'
import QuestionarioView from '../views/QuestionarioView.vue';

const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/',
    name: 'intro',
    component: IntroPage
  },
  {
    path: '/new-trip',
    name: 'new-trip',
    component: NewTrip
  },
    {
    path: '/my-trips',
    name: 'my-trips',
    component: MyTrips
  },
    {
    path: '/update-km',
    name: 'update-km',
    component: UpdateKm
  },
    {
    path: '/scan-ticket',
    name: 'scan-ticket',
    component: CompleteTripFormTicket
  },

  {
    path: '/select-turno',
    name: 'select-turno',
    component: SelectTurno
  },

  {
    path: '/select-vehicle',
    name: 'select-vehicle',
    component: SelectVehicle
  },

  {
    path: '/questionario/:documentTypeId/:driverId/:vehicleId/:trayId/:shiftId/:driverdocumentid',
    name: 'questionario',
    component: QuestionarioView,
  },

  {
    name: 'logout',
    path: '/logout',
    redirect: to => {
        console.log(to);
        return {name: 'login'};
    },
    meta: {
        requiresAuth: false,
        module: 'authentication'
    }
},


  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
