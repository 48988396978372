<template id="home">
  <transition appear enter-active-class="animated fadeIn">
    <div>
      <div class="p-grid">
        <div class="p-col-12 p-text-center">
          <img alt="logo corporativo" src="@/assets/logo.svg" style="width:2rem;" />
        </div>

        <div class="p-col-12 p-text-center">
          <p class="subtitle has-text-dark">Seleccione Vehiculo</p>
          <AutoComplete
            v-model="selectedVehicle"
            :suggestions="filteredVehiclesMultiple"
            @complete="searchVehicleMultiple($event)"
            placeholder="Busque vehiculo"
            field="formatted"
          ></AutoComplete> 

          <span v-if="selectedVehicle">
            <br />
            <span v-if="selectedVehicle.kilometers && selectedVehicle.last_medition_km_date">
              {{ getNumbersDots(selectedVehicle.kilometers) }} KM ({{ getLocalFromUtc(selectedVehicle.last_medition_km_date) }})
            </span>
            <span v-else class="has-text-danger">Sin kilometraje</span>
          </span>
        </div>

        <div class="p-col-12 p-text-center">
          <p class="subtitle has-text-dark">Seleccione semirremolque</p>
          <AutoComplete
            v-model="selectedTray"
            :suggestions="filteredTraysMultiple"
            @complete="searchTrayMultiple($event)"
            placeholder="Busque semirremolque"
            field="formatted"
          ></AutoComplete>
        </div>

p-button-raised p-button-rounded

        <div class="p-col-12">

           <ButtonPrime label="Usar Vehiculo" @click="openModalConfirmation()" class="p-button-raised p-button-rounded" />

        </div>

        <div class="p-col-12">
             <ButtonPrime label="Cancelar" @click="goback()" class="p-button-raised p-button-rounded" />
     
        </div>
      </div>

      <DialogPrime header="Confirme uso de vehiculo" v-model:visible="isOpenModalConfirmation" modal>
        <p class="has-text-dark">¿Está seguro de que se encuentra en el vehículo?</p>
        <p class="has-text-dark" v-if="selectedVehicle">
          <br />
          <strong>{{ selectedVehicle.plate }}</strong>
           <br />
          <strong>{{ selectedTray.plate }}</strong>
        </p>
        <br />
        <template #footer>
          <button class="p-button p-component p-button-warning" @click.prevent="useVehicle">Usar vehículo</button>
          <button class="p-button p-component" @click.prevent="isOpenModalConfirmation = false">Cancelar</button>
        </template>
      </DialogPrime>
    </div>
  </transition>
</template>
<script>

    import {mapGetters} from 'vuex';
    import moment from "moment";
    import VehicleService from '@/services/VehicleService';
    import TecladoNumerico from '@/components/TecladoNumerico';
    import ShiftService from '@/services/WorkShiftService';

    let VehicleInformation = {};
    (function () {
        this.name = 'report-an-event-page';
        // let _self = null;

        this.created = function () {
            // _self = this;
            // sACAR CONTRATOs DEL USUARIO.
            this.$store.dispatch('form/submit', false);

            this.getVehicles();
            this.getTrays();

  
        };

        this.mounted = function () {

        };

        this.data = function () {
            return {
                vehicle_kilometers: '',
                filteredVehiclesMultiple: [],
                selectedVehicle: null,
                selectedTray: null,
                isOpenModalConfirmation: false,
                all_vehicles: [],
                 all_tray: [],
                filteredTraysMultiple: [],

            }
        };
        this.components = {TecladoNumerico};
        this.methods = {
            openModalConfirmation(){
                let result = {
                isError: false,
                message: null
            }

            if(this.selectedVehicle == null){
                    result.message = 'Debe seleccionar vehiculo';
                      this.$store.dispatch('form/result', result);
                    return;
                }
  
              if(this.selectedTray == null){
                    result.message = 'Debe seleccionar remolque';
                      this.$store.dispatch('form/result', result);
                      this.$store.dispatch('form/submit', false);
                    return;
                }

                this.isOpenModalConfirmation = true;
            },
            getNumbersDots: function (num) {
              return num.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
            },
          getLocalFromUtc(date){
            if(date){
              var stillUtc = moment.utc(date).toDate();
              var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm');
              return local;
            }
          },

        useVehicle(){
            let result = {
                isError: false,
                message: null
            }
            this.$store.dispatch('form/submit', true);
            if(this.selectedVehicle == null){
                    result.message = 'Debe seleccionar vehiculo';
                      this.$store.dispatch('form/result', result);
                      this.$store.dispatch('form/submit', false);
                    return;
                }

              if(this.selectedTray == null){
                    result.message = 'Debe seleccionar remolque';
                      this.$store.dispatch('form/result', result);
                      this.$store.dispatch('form/submit', false);
                    return;
                }
                    const log = {'driver_id': this.username, 
                                  'vehicle_id': this.selectedVehicle.vehicle_id, 
                                  'tray_id': this.selectedTray.vehicle_id, 
                                  'shift_id': this.turno.water_work_shift_id, 
                                  'activity': 'SELECT-VEHICLE'};

                    ShiftService.setDriverLog(log).then((response) => {
                        this.selectedVehicle = response.data;
                        this.$store.dispatch('form/submit', false);
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
                    this.$store.dispatch('vehicle/set-vehicle', this.selectedVehicle);
                    this.$store.dispatch('vehicle/set-tray', this.selectedTray);
                    this.$router.push({name: 'home'});
          },
            setKilometers(event){
              this.vehicle_kilometers = event;
              this.vehicleValidation();
          },
          vehicleValidation(){
            let result = {
                isError: false,
                message: null
            }

            this.$store.dispatch('form/submit', true);
            if(this.vehicle_kilometers !== null){
              if(parseInt(this.vehicle_kilometers) <= 0){
                result.isError = true;
                    result.message = 'Numero debe ser mayor a 0';
                    this.vehicle_kilometers = '';
                      this.$store.dispatch('form/result', result);
                      this.$store.dispatch('form/submit', false);
                    return;
              }
            }
              this.$store.dispatch('form/submit', false); 
          },
            getVehicles(){
                 VehicleService.getAll().then((response) => {
                        this.all_vehicles = response.data;
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },

            getTrays(){
                 VehicleService.getAllTray().then((response) => {
                        this.all_tray = response.data;
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
            getVehiclesById(id){
                console.log("getting vehicle ID... ");
                 VehicleService.get_by_id(id).then((response) => {
                        this.selectedVehicle = response.data;
                        this.$store.dispatch('form/submit', false);
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
            getTrayById(id){
                 console.log("getting tray ID... ");
                 VehicleService.get_by_id(id).then((response) => {
                        this.selectedTray = response.data;
                        this.$store.dispatch('form/submit', false);
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },




  searchVehicleMultiple: function(event) {
  if (event.query && event.query.trim() !== '' && event.query.length > 0 && this.all_vehicles) {
    event.query = event.query.toLowerCase();
    this.filteredVehiclesMultiple = this.all_vehicles
      .filter(vehicle => {
        const vehicleType = vehicle.vehicle_type ? vehicle.vehicle_type.toLowerCase() : '';
        const excludedTypes = ['semirremolque chasis', 'chasis', 'semi remolque'];

        return vehicle.plate.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim()) &&
               !excludedTypes.includes(vehicleType);
      })
      .map(vehicle => ({
        ...vehicle,
        formatted: this.formatVehicle(vehicle)
      }));
  } else {
    this.filteredVehiclesMultiple = [];
  }
},


searchTrayMultiple: function(event) {
  if (event.query && event.query.trim() !== '' && event.query.length > 0 && this.all_tray) {
    event.query = event.query.toLowerCase();
    this.filteredTraysMultiple = this.all_tray
      .filter(tray => tray.plate.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim()))
      .map(tray => ({
        ...tray,
        formatted: this.formatVehicle(tray)
      }));
  } else {
    this.filteredTraysMultiple = [];
  }
},
formatVehicle(vehicle) {
  let formattedString = vehicle.plate;
  if (vehicle.vehicle_type) {
    formattedString += ` - ${vehicle.vehicle_type}`;
  }
  return formattedString;
},
              goback: function () {
                this.$router.go(-1);
              },

              goTo: function (route, param) {
                if(param === null)
                    this.$router.push({name: route});
                else
                    this.$router.push({
                        name: route,
                        params: {
                            contract_id: param
                        }
                    });
            },
            
        };
        this.watch = {
          
        };

        this.computed = {
          ...mapGetters({
                error: 'form/error-states',
                sv: 'vehicle/vehicle',
                st: 'vehicle/tray',
                username: 'auth/user',
                turno: 'turno/turno',
                contract: 'contract/contract',
            }),
        };
    }).apply(VehicleInformation);

    export default VehicleInformation
</script>

<style scoped>

input.p-component {
    width: 100% !important;
}
</style>