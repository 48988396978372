<template>
    <div v-if="result.message" style="z-index: 9999 !important; margin-top: 40px; position:fixed; right: 15px">
        <br /><br /><br />
        <MessagePrime v-if="!result.isError" severity="success" :life="7000" :sticky="false" >{{result.message}}</MessagePrime> 
        <MessagePrime v-else severity="error" :life="7000" :sticky="false" >{{result.message}}</MessagePrime> 
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    let ResultMessage = {};
    (function () {
        this.name = 'resultmessage-component';
        this.created = function () {};
        this.data = function () {
            return {}
        };
        this.methods = {
        };
        this.mounted = function () {};
        this.components = {}
        this.computed = {
            ...mapGetters({
                result: 'form/result',
            })
        };
        this.watch = {
            'result.message': function (newValue) {
                if (newValue) {
                    if (this.handler) clearTimeout(this.handler);
                    let _self = this;
                    setTimeout(function () {
                        _self.result.message = null;
                    }, 7000);
                }
            }
        };
        this.props = [];
    }).apply(ResultMessage);
    export default ResultMessage;
</script>

<style scoped>
    
</style>
