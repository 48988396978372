<template>
    <ToolbarComp v-if="showTopBar"></ToolbarComp>
    
      <result-message></result-message>

      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
        <br /><br /><br /><br />
      </router-view>
      
      <LoaderComp v-if="isLoading"></LoaderComp>
    
    <BottomBar v-if="showBottomBar"></BottomBar>
</template>

<script>
    import {mapGetters} from 'vuex'
    import ToolbarComp from '@/components/ToolbarComp'
    import LoaderComp from '@/components/LoaderComp'
    import ResultMessage from '@/components/ResultMessage'
    import BottomBar from '@/components/BottomBar'

export default {
  name: 'app',
  components: {
    ToolbarComp, LoaderComp, ResultMessage, BottomBar
  },
  computed: {
             showBottomBar: function () {
                const dontShowIn = ['login','intro', 'select-turno', 'select-vehicle'];
                return dontShowIn.indexOf(this.$route.name) === -1;
            },
             showTopBar: function () {
                const dontShowIn = ['login','intro'];
                return dontShowIn.indexOf(this.$route.name) === -1;
            },
             ...mapGetters({
                isLoading: 'form/is-submiting'
            })
        }
  }


</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.p-button-secondary {
    color: white !important;
    background: #0b0b0c !important;
    border: 1px solid #8f98a1 !important;
}

body{
  background-color: white;
  margin-left: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.8s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>