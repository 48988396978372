<template>
  <div class="answers">
    <button @click="emitAnswer('MALA')" :class="['boton-mala', { 'is-selected': selectedAnswer === 'MALA' }]">MALA</button>
    <button @click="emitAnswer('NO APLICA')" :class="['boton-no-aplica', { 'is-selected': selectedAnswer === 'NO APLICA' }]">NO APLICA</button>
    <button @click="emitAnswer('BUENA')" :class="['boton-buena', { 'is-selected': selectedAnswer === 'BUENA' }]">BUENA</button>
  </div>
</template>

<script>
export default {
  props: {
    initialAnswer: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      selectedAnswer: this.initialAnswer // Valor inicial recibido como prop
    };
  },
  methods: {
    emitAnswer(answer) {
      this.selectedAnswer = answer;
      this.$emit('answerSelected', answer); // Emitimos el evento con el valor seleccionado
    }
  }
};
</script>

<style scoped>
.answers {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.answers button {
  margin: 5px;
  padding: 15px 30px;
  font-size: 18px;
  width: 100px;
  text-align: center;
}

.boton-mala {
  background-color: white;
  border-color: red;
  color: black;
}

.boton-no-aplica {
  background-color: white;
  border-color: yellow;
  color: black;
}

.boton-buena {
  background-color: white;
  border-color: green;
  color: black;
}

.is-selected {
  background-color: rgb(131, 131, 254) !important;
  color: black !important;
}
</style>
