<template>
  <CardPrime class="info-card">
    <template #title>
      <div class="card-title" v-if="contract && turno">
        Turno {{ formattedShiftType }} {{ translatedState }}
      </div>
    </template>
    <template #content>
      <div v-if="contract && turno">
        <div class="info-row">
          <strong>Contrato:</strong>
          <span>{{ contract.name }}</span>
        </div>
        <div class="info-row">
          <strong>Inicio del Turno:</strong>
          <span>{{ formattedShiftStart }}</span>
        </div>
        <div class="info-row">
          <strong>Fin del Turno:</strong>
          <span>{{ formattedShiftFinish }}</span>
        </div>

        <div class="info-row" v-if="numero_viajes > 0">
          <strong>Numero de Viajes:</strong>
          <span>{{ numero_viajes }}</span>
        </div>


        
      </div>
      <div v-else>
        <p class="text-center">Sin selección</p>
      </div>
    </template>
  </CardPrime>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  props: ['numero_viajes'],
  computed: {
    ...mapGetters('contract', ['contract']),
    ...mapGetters('turno', ['turno']),
    translatedState() {
      switch (this.turno.shift_status) {
        case 'CREATED':
          return 'Creado';
        case 'OPEN':
          return 'Abierto';
        case 'CLOSE':
          return 'Cerrado';
        default:
          return 'Estado Desconocido';
      }
    },
    formattedShiftType() {
      return this.turno.work_shift_type.charAt(0).toUpperCase() + this.turno.work_shift_type.slice(1);
    },
    formattedShiftStart() {
      return moment(this.turno.work_shift_start).format('DD/MM/YYYY HH:mm');
    },
    formattedShiftFinish() {
      return moment(this.turno.work_shift_finish).format('DD/MM/YYYY HH:mm');
    },
    statusColor() {
      switch (this.turno.shift_status) {
        case 'CREATED':
          return 'status-created';
        case 'OPEN':
          return 'status-open';
        case 'CLOSE':
          return 'status-closed';
        default:
          return '';
      }
    }
  }
};
</script>

<style scoped>
.info-card {
  max-width: 300px;
  margin: 10px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  border: 1px solid #ccc;
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.status-created {
  color: #999;
}

.status-open {
  color: green;
}

.status-closed {
  color: red;
}

.info-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
}
</style>
