
<template>
  <div class="m-3">
    <br /><br /><br />
    <h1>Saque imagen del ticket de pesaje</h1>
    <ScannTicket :fields="ticket_structure" @imageUploaded="handleImageUploaded"/>
    <ConfirmationTicketDialog :show="showDialog" :data="responseData" @confirm="handleConfirm" @cancel="handleCancel"/>
  </div>
</template>

<script>
import ScannTicket from "@/components/ScannTicket.vue";
import ConfirmationTicketDialog from "@/components/ConfirmationTicketDialog.vue";

export default {
  components: {
    ScannTicket,
    ConfirmationTicketDialog
  },
  data() {
    return {
      ticket_structure: ['Titulo', 'Impreso', 'numeroGuia', 'numeroPesaje', 'Patente', 'TipoPesaje', 'Transportista', 'Conductor', 'Cliente', 'Producto', 'subtituloEntrada', 'FechaHora', 'Operador', 'SubtituloSalida', 'FechaHora', 'Operador', 'BRUTO(KG)', 'TARA(KG)', 'NETO(KG)'],
      showDialog: false,
      responseData: null
    };
  },
  methods: {
    handleImageUploaded(response) {
      this.responseData = response;
      this.showDialog = true;
    },
    handleConfirm(editedData) {
      this.showDialog = false;
      // Aquí puedes manejar la confirmación, por ejemplo, enviar los datos editados a otro lugar.
      console.log(editedData); // Estos son los datos editados
    },
    handleCancel() {
      this.showDialog = false;
    }
  }
};
</script>
