<template id="home">
    <div class="card p-fluid mt-6">
        <br /><br /><br />
    <h5 class="text-black">Actualizacion de Kilometrajes vehiculo=</h5>

    <div class="grid m-3">
        <div class="col-12">
        </div> 
        <div class="col-12">
            <autoComplete id="vehicle_selector_id" v-model="selectedVehicle" field="plate" :suggestions="filteredVehicleMultiple" @complete="searchVehicleMultiple($event)" placeholder="Seleccione vehiculo"></autoComplete>
        </div>
        <div class="col-12">
        </div> 
        <div class="col-12" v-if="selectedVehicle != null">
            <p> Ultima Medicion: {{formatDate(selectedVehicle.last_medition_km_date)}}</p>
             <p>Kilometraje Actual: {{ formatNumber(selectedVehicle.kilometers) }} KM</p>
        </div> 
        <div class="col-12" v-if="selectedVehicle != null">
           <p>  Nuevo Kilometraje: <InputText type="number" v-model="new_kilometrs"/></p>
        </div> 
        <div class="col-12"  v-if="selectedVehicle != null">
            <ButtonPrime class="w-6 mt-3 p-button-warning" :label="'Actualizar (actualizados '+ lo_ingresado.length+')'" @click.prevent="actualizar()"/>
        </div> 
        <div class="col-12" v-if="lo_ingresado.length > 0">
            <DataTable :value="lo_ingresado" responsiveLayout="scroll">
                <Column field="vehicle" header="Vehiculo"></Column>
                <Column field="new_kilometrs" header="Nuevo Kilometraje"></Column>
            </DataTable>
        </div>
    </div>
 
    <br /><br /><br /><br /><br /><br /><br />

    </div>
</template>


<script>
    

  
    import VehicleService from '@/services/VehicleService';

    import {mapGetters} from 'vuex';
    


    let UpdateKilometersVehicle = {};
    (function () {
        this.name = 'update-kilometers-vehicle-page';
        // let _self = null;

        this.created = function () {
            this.getAllVehicle();
        };

        this.mounted  = function () {
            window.scrollTo(0, 0);
        };

        this.data = function () {
            return {
               selectedVehicle: null,
               all_vehicle: [],
               filteredVehicleMultiple: [],
               lo_ingresado: [],
               new_kilometrs: null,
            }
        };
        this.methods = {
             formatNumber(number) {
    if (!number) return '0'; // Retorna '0' si el número no está definido o es nulo
    return number.toLocaleString('de-DE'); // Utiliza 'de-DE' para obtener el formato con puntos (1.000.000)
  },
              formatDate(date) {
    if (!date) return 'No disponible'; // Manejo de fechas nulas
    return new Date(date).toLocaleString('es', { 
      year: 'numeric', 
      month: '2-digit', 
      day: '2-digit', 
      hour: '2-digit', 
      minute: '2-digit', 
      second: '2-digit' 
    });
  },
            clearForm(){
                this.selectedVehicle= null;
                this.new_kilometrs = null;
                 this.$router.push({ name: 'logout' });
            },
            actualizar(){
                 //falta validacion vehiculo not null, kilometraje igual, mayor 0.
                VehicleService.newKilometers(this.selectedVehicle.vehicle_id, parseInt(this.new_kilometrs)).then((response) => {
                    if(response.data.status == 'OK'){
                      this.$store.dispatch('form/result', {isError: false, message: response.data.message});
                      this.lo_ingresado.push({'vehicle': this.selectedVehicle.plate, 'new_kilometrs': this.new_kilometrs});
                      this.clearForm();
                      document.getElementById("vehicle_selector_id").focus();
                    }else{
                      this.$store.dispatch('form/result', {isError: true, message: response.data.message});
                    }
                    //this.goTo('home');
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });

            },

            searchVehicleMultiple(event){
                var filteredResultVehicle = [];
                if (event.query !== null && event.query.trim() !== '' && event.query.length > 0) {
                    event.query = event.query.toLowerCase();
                    for (var i = 0; i < this.all_vehicle.length; i++) {
                        if (this.all_vehicle[i].plate.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim())  
                        ) {
                            filteredResultVehicle.push(this.all_vehicle[i])
                        }
                    }
                    this.filteredVehicleMultiple = filteredResultVehicle;
                } else {
                    return [];
                }   
            },
            getAllVehicle(){
                 VehicleService.getAll().then((response) => {
                        this.all_vehicle= response.data;
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
            isInGroups: function (groupName) {
                for (var i = 0; i < this.groups.length; i++) {
                        if (this.groups[i].name === groupName) {
                            return true;
                        }  
                }
                return false;
            },
            goTo: function (route) {
                this.$router.push({name: route});
            },
        };
        this.computed = {
             ...mapGetters({
                groups: 'auth/groups',
                error: 'form/error-states'
            })
        };
        this.watch = {

        };

        this.props = [];
    }).apply(UpdateKilometersVehicle);

    export default UpdateKilometersVehicle
</script>
