<template>
  <DialogPrime
    :visible="internalShow" 
    :modal="true" 
    :style="{ width: '50vw' }" 
    @hide="closeModal"
    header="Ingreso TICKET">
    <form class="form-container" @submit.prevent="confirm">
      <div class="input-group">
        <label>Número Guía</label>
        <input type="text" v-model="internalData.numeroGuia">
      </div>
      <div class="input-group">
        <label>Patente</label>
        <input type="text" v-model="internalData.Patente" disabled>
      </div>
      <div class="input-group">
        <label>Conductor</label>
        <input type="text" v-model="internalData.Conductor" disabled>
      </div>
      <div class="input-group half">
        <label>BRUTO(KG)</label>
        <input type="text" v-model="internalData['BRUTO(KG)']">
      </div>
      <div class="input-group half">
        <label>TARA(KG)</label>
        <input type="text" v-model="internalData['TARA(KG)']">
      </div>
      <div class="input-group">
        <label>NETO(KG)</label>
        <input type="text" v-model="internalData['NETO(KG)']">
      </div>
      <div class="button-group">
        <buttonPrime type="submit" class="p-button-raised p-button-rounded large-button">Confirmar</buttonPrime>
        <buttonPrime type="button" class="p-button-raised p-button-rounded large-button" @click="cancel">Cancelar</buttonPrime>
      </div>
    </form>
  </DialogPrime>
</template>

<script>


export default {
  components: {
    
  },
  props: {
    show: Boolean,
    data: Object
  },
  data() {
    return {
      internalShow: this.show,
      internalData: JSON.parse(JSON.stringify(this.data))
    };
  },
  watch: {
    show(newVal) {
      this.internalShow = newVal;
    },
    data(newVal) {
      this.internalData = newVal ? JSON.parse(JSON.stringify(newVal)) : {};
    }
  },
  methods: {
    closeModal() {
      this.internalShow = false;
      this.$emit('update:show', false);
    },
    confirm() {
      this.$emit('confirm', this.internalData);
      this.closeModal();
    },
    cancel() {
      this.$emit('cancel');
      this.closeModal();
    }
  }
};
</script>


<style scoped>
.modal-card {
  max-width: 600px; /* Limiting max width for larger screens */
  width: 95%; /* Responsive width on smaller devices */
  margin: auto;
}

.modal-card-body {
  display: flex;
  flex-direction: column;
}

.form-container {
  display: flex;
  flex-direction: column;
}

.input-group {
  margin-bottom: 1rem;
}

.input-group.half {
  flex: 1 1 50%; /* half width for BRUTO and TARA */
}

.label {
  margin-bottom: 0.5rem;
}

input[type="text"] {
  width: 100%; /* Full width inputs */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button-group {
  display: flex;
  justify-content: center; /* Centering buttons */
  gap: 10px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button.confirm {
  background-color: #4CAF50;
  color: white;
}

button.cancel {
  background-color: #f44336;
  color: white;
}

@media (max-width: 600px) {
  .input-group.half {
    flex: 1 1 100%; /* Stack inputs vertically on small screens */
  }

  .button-group {
    flex-direction: column;
  }
}
</style>

