<template>
  <div class="questionnaire">
    <div v-if="isLoading" class="loading">
      <p>Loading...</p>
    </div>
    <div v-else>
      <div v-for="category in categories" :key="category.id" class="category-section">
        <div class="category-title">
          <h3>{{ category.name }}</h3>
        </div>
        <div v-for="(question, index) in category.questions" :key="question.id" class="question">
          <h4>{{ question.question }}</h4>
          
          <!-- Usamos el nuevo componente -->
          <BuenoMalo v-if="question.question_type.name === 'bueno-malo'" :initialAnswer="question.selectedAnswer" @answerSelected="handleAnswer(category.id, index, $event)" />
          <BuenoMaloNoAplica v-if="!question.question_type.name " :initialAnswer="question.selectedAnswer" @answerSelected="handleAnswer(category.id, index, $event)" />
          <SiNo v-if="question.question_type.name === 'si-no'" :initialAnswer="question.selectedAnswer" @answerSelected="handleAnswer(category.id, index, $event)" />
          <RangoUnoCuatro v-if="question.question_type.name  === 'rango-cero-cuatro'" :initialAnswer="question.selectedAnswer" @answerSelected="handleAnswer(category.id, index, $event)" />
          
          <div v-if="question.have_invalid_date" class="expiration-date">
            <label for="expirationDate">Fecha de Expiración:</label>
            <input type="date" v-model="question.expirationDate" @change="handleExpirationDateChange(category.id, index)" id="expirationDate" />
          </div>
        </div>
      </div>
      <ButtonPrime @click="finalizarCuestionario" class="p-button-raised p-button-rounded">Enviar</ButtonPrime>
    </div>
  </div>
</template>

<script>
import { getQuestionsByCategory, submitAnswers, finalizarCuestionario } from '../services/QuestionsService';
import BuenoMaloNoAplica from '@/components/BuenoMaloNoAplica.vue';
import BuenoMalo from '@/components/BuenoMalo.vue';

import SiNo from '@/components/SiNo.vue';
import RangoUnoCuatro from '@/components/RangoUnoCuatro.vue';

export default {
  components: {
    BuenoMaloNoAplica,
    BuenoMalo,
    SiNo,
    RangoUnoCuatro
  },
  data() {
    return {
      categories: [],
      isLoading: true,
      responseMessage: ''
    };
  },
  methods: {
    fetchQuestions() {
      const { documentTypeId, driverId, vehicleId, trayId, shiftId } = this.$route.params;
      getQuestionsByCategory(documentTypeId, driverId, vehicleId, trayId, shiftId)
        .then(response => {
          this.categories = response.data.categories;
          this.categories.forEach(category => {
            category.questions.forEach(question => {
              const answer = question.answers[0];
              question.selectedAnswer = answer ? answer.value : null;
              question.expirationDate = answer ? answer.expires : null;
            });
          });
          this.isLoading = false;
          console.log(this.categories);
        })
        .catch(error => {
          console.error('Error fetching questions:', error);
          this.isLoading = false;
        });
    },
    handleAnswer(categoryId, index, answer) {
      const category = this.categories.find(cat => cat.id === categoryId);
      const question = category.questions[index];
      question.selectedAnswer = answer;

      this.submitAnswer(question);
    },
    handleExpirationDateChange(categoryId, index) {
      const category = this.categories.find(cat => cat.id === categoryId);
      const question = category.questions[index];
      this.submitAnswer(question);
    },
    submitAnswer(question) {
      const { driverId, vehicleId, trayId, shiftId } = this.$route.params;
      const answer = {
        shift_id: shiftId,
        document_question_id: question.id,
        value: question.selectedAnswer,
        driver_id: driverId,
        vehicle_id: vehicleId,
        tray_id: trayId,
        expires: question.expirationDate
      };

      submitAnswers({ answers: [answer] })
        .then(response => {
          console.log('Answer submitted successfully:', response.data);
          this.responseMessage = response.data.message;

          this.$store.dispatch('form/result', {
            isError: false,
            message: this.responseMessage
          });
        })
        .catch(error => {
          console.error('Error submitting answer:', error);
          this.responseMessage = 'Error enviando respuesta. Intente denuevo.';

          this.$store.dispatch('form/result', {
            isError: true,
            message: this.responseMessage
          });
        });
    },
    finalizarCuestionario() {
      const { driverId, vehicleId, trayId, shiftId, documentTypeId, driverdocumentid } = this.$route.params;

      finalizarCuestionario({
        driver_id: driverId,
        vehicle_id: vehicleId,
        tray_id: trayId,
        shift_id: shiftId,
        document_type_id: documentTypeId,
        driver_document_id: driverdocumentid
      })
        .then(response => {
          this.$store.dispatch('form/result', {
            isError: response.data.status === 'ERROR',
            message: response.data.message || 'Ha ocurrido un error.'
          });

          this.$router.push('/home');
        })
        .catch(error => {
          const errorMessage = error.response?.data?.message || 'Error desconocido';
          this.$store.dispatch('form/result', {
            isError: true,
            message: errorMessage
          });

          this.$router.push('/home');
        });
    }
  },
  created() {
    this.fetchQuestions();
  }
};
</script>
