import axios from 'axios';

const baseUrl = `${process.env.VUE_APP_API_URL}`;

export default {
    getAll() {
        const url = `${baseUrl}/list_document_shifts`;
        return axios.get(url);
    },
    create(data) {
        const url = `${baseUrl}/create_driver_document_shift/`;
        return axios.post(url, data);
    },

    getDriverDocumentsByShift(turno, vehicle, tray, username){
        const url = `${process.env.VUE_APP_API_URL}/work_shifts`;
        let data = {operation: 'get_driver_documents_by_shift',
        shift_id: turno,
        vehicle_id: vehicle,
        tray_id: tray,
        driver_id: username,
                    }
        return axios.post(url, data)
    },

};
