import axios from "axios";

export default {

    get(id) {
        const url = `${process.env.VUE_APP_API_URL}/trips`;
        let data = {operation: 'get', id: id}
        return axios.post(url, data)
    },
    save(trip) {
        const url = `${process.env.VUE_APP_API_URL}/trips`;
        let data = {operation: 'save', trip: trip}
        return axios.post(url, data)
    },
    driverEditing(trip) {
        const url = `${process.env.VUE_APP_API_URL}/trips`;
        let data = {operation: 'driver-editing-trip', trip: trip}
        return axios.post(url, data)
    },
    closeTrip(t,  employee_id, zone, current_work_shift){
        const url = `${process.env.VUE_APP_API_URL}/trips`;
        let data = {operation: 'close-water-trip', 
                    water_trip_id: t.water_trip_id,
                    closer_employee_id: employee_id,
                    unload_zone_id: zone.unload_zone_id,
                    closer_work_shift_id: current_work_shift,
                 }
        return axios.post(url, data)
    },
    getByWorkShift(id) { 
        const url = `${process.env.VUE_APP_API_URL}/trips`;
        let data = {operation: 'get-by-shift', id: id}
        return axios.post(url, data)
    },

     getByDriver(id) { 
        const url = `${process.env.VUE_APP_API_URL}/trips`;
        let data = {operation: 'get-trips-by-driver', id: id}
        return axios.post(url, data)
    },

    getByWorkShiftDriver(id, employee_id) { 
        const url = `${process.env.VUE_APP_API_URL}/trips`;
        let data = {operation: 'get-by-shift-driver', id: id, employee_id:employee_id}
        return axios.post(url, data)
    },

    getOpenTripsContract(contract_id, work_shift_id) { 
        const url = `${process.env.VUE_APP_API_URL}/trips`;
        let data = {operation: 'get-open-trips-by-contract', contract_id: contract_id, work_shift_id: work_shift_id}
        return axios.post(url, data)
    },

    getTripByGuiaNumber(guia_number){
        const url = `${process.env.VUE_APP_API_URL}/trips`;
        let data = {operation: 'get-trip-by-guia-number', guia_number: guia_number}
        return axios.post(url, data)
    },

    numberTripOtherShift(shift_work_id){
        const url = `${process.env.VUE_APP_API_URL}/trips`;
        let data = {operation: 'get-number-trip-other-shift', shift_work_id: shift_work_id}
        return axios.post(url, data)
    }

}
