<template>
  <div class="p-fluid">
  
    <p class="text-center">NUEVO VIAJE</p>

    <SelectedTurno v-if="turno && contract" ></SelectedTurno>
    <DropdownPrime v-if="!contract"  v-model="selectedContract" :options="contracts" optionLabel="name" placeholder="Seleccione contrato" class="dropdown" />

    <div v-if="!turno" class="flex-container">
      <DropdownPrime
        v-model="selectedShift"
        :options="formattedShifts"
        optionLabel="displayLabel"
        placeholder="Seleccione un turno"
        :disabled="!selectedContract"
        class="dropdown flex-item-grow"
      />
      <p v-if="shifts.length == 0 && selectedContract" class="text-center">Sin Turno, comuníquese con su supervisor</p>
    </div>


    <h6 class="text-center">Vehiculo - Remolque</h6>
    Vehiculo: {{vehicle.plate}}
    <br />
    Remolque: {{tray.plate}}
    <!--DropdownPrime  v-model="selectedVehicle" :options="vehicles" optionLabel="plate" placeholder="Seleccione un vehículo" filter :disabled="!selectedContract" class="dropdown" />
    <DropdownPrime v-model="selectedTray" :options="vehicles" optionLabel="plate" placeholder="Seleccione un remolque" filter :disabled="!selectedContract" class="dropdown" /-->

    <h6 class="text-center">Origen - Destino</h6>
    <DropdownPrime v-model="selectedOrigin" :options="all_origins" optionLabel="name" placeholder="Seleccione origen" :disabled="!selectedContract || !selectedShift" class="dropdown" />
    <DropdownPrime v-model="selectedDestination" :options="all_destination" optionLabel="name" placeholder="Seleccione Destino" :disabled="!selectedContract || !selectedShift" class="dropdown" />

    <!--DropdownPrime  v-model="selectedDestination" :options="all_destination" optionLabel="name" placeholder="Seleccione destino" filter :disabled="!selectedContract || !selectedShift" class="dropdown" />
    <DropdownPrime v-if="!shouldHideDropdowns" v-model="selectedRute" :options="all_rute" optionLabel="name" placeholder="Seleccione ruta" filter :disabled="!selectedContract || !selectedShift" class="dropdown" />
    <DropdownPrime  v-model="unloadZoneSelected" :options="unloadZones" optionLabel="name" placeholder="Zona descarga" filter :disabled="!selectedContract || !selectedShift" class="dropdown" /-->

    <h6 class="text-center">Datos Guia</h6>

 <div class="flex-container">
  <div>
    <CheckBox v-model="isManualGuide" binary="true" inputId="manualGuideCheck"></CheckBox>
    <label for="manualGuideCheck">Guía Manual</label>
  </div>
</div>

      <div class=" full-width" v-if="!isManualGuide">
        <TecladoNumerico
          v-model="guiaNumber"
          titleKeyBoard="Número de Guía"
          :keyboardValue="guiaNumber"
          :errorState="error.inputGuiaNumber || error.inputGuiaNumberFormat"
          placeholderFakeField="Número de Guía"
          @readyFromKeyboard="updateGuiaNumber"
        />
      </div>
          <p v-if="error.inputGuiaNumber" class="error-message">Complete Numero Guia</p>
    <p v-if="error.inputGuiaNumberFormat" class="error-message">Formato incorrecto </p>

<div class="flex-container" v-if="!isManualGuide">
  <!-- Agrega un label con el id asociado al Calendar con fecha y hora -->
  <label for="guiaDateTime">Fecha y Hora de la Guía</label>
  <CalendarPrime v-model="guiaDate" inputId="guiaDateTime" :disabled="!selectedContract || !selectedShift" showTime class="inputtext" :class="{'error-border': error.inputGuiaDate}"/>
</div>





    <h6 class="text-center">Datos Numericos</h6>



    <TecladoNumerico
          v-model="neto"
          titleKeyBoard="Neto (kg)"
          :keyboardValue="neto"
          :errorState="error.inputMeterCubic || error.inputMeterCubicRange || error.inputMeterCubicFormat"
          placeholderFakeField="Neto (kg)"
          @readyFromKeyboard="updateNeto"
        />
    <p v-if="error.inputMeterCubic" class="error-message">Complete Neto</p>
    <p v-if="error.inputMeterCubicFormat" class="error-message">Neto Formato incorrecto </p>
    <p v-if="error.inputMeterCubicRange" class="error-message">Neto Rango incorrecto </p>

     <TecladoNumerico
          v-model="tara"
          titleKeyBoard="Tara (kg)"
          :keyboardValue="tara"
          :errorState="error.inputTaraFormat || error.inputTara || error.inputMeterCubicRange"
          placeholderFakeField="Tara (kg)"
          @readyFromKeyboard="updateTara"
        />

    <p v-if="error.inputTara" class="error-message">Complete Tara</p>
    <p v-if="error.inputTaraFormat" class="error-message">Tara Formato incorrecto </p>
    <p v-if="error.inputMeterCubicRange" class="error-message">Tara Rango incorrecto </p>

   <TecladoNumerico
          v-model="bruto"
          titleKeyBoard="Bruto (kg)"
          :keyboardValue="Bruto"
          :errorState="error.inputBruto || error.inputMeterCubicRange || error.inputBrutoFormat"
          placeholderFakeField="Bruto (kg)"
          @readyFromKeyboard="updateBruto"
        />
    <p v-if="error.inputBruto" class="error-message"> Complete bruto</p>
    <p v-if="error.inputBrutoFormat" class="error-message">Bruto Formato incorrecto </p>
    <p v-if="error.inputMeterCubicRange" class="error-message">Bruto Rango incorrecto </p>

    <!--InputText v-model="vuelta" placeholder="Vuelta" :disabled="!selectedContract || !selectedShift" class="inputtext" />
    <p v-if="error.inputBruto" class="error-message">Complete bruto</p-->


    <ButtonPrime label="Guardar Viaje" icon="pi pi-check" @click="save" :disabled="!selectedContract" class="button mt-5" />
  </div>
</template>

<script>
    import Stepper from '@/components/Stepper';
    import ContractService from '@/services/ContractService';

    import VehicleService from '@/services/VehicleService';
    import UnloadZoneService from '@/services/UnloadZoneService';
    import WorkShiftService from '@/services/WorkShiftService';
    import TripService from '@/services/TripService';
    import { mapGetters } from 'vuex';

    import AutoComplete from 'primevue/autocomplete';
    import Dropdown from 'primevue/dropdown';
    import TecladoNumerico from '@/components/TecladoNumerico';
    import SelectedTurno from '@/components/SelectedTurno';
    import moment from "moment";

    let NewTripage = {};
    (function () {
        this.name = 'new-trip';
        let _self = null;

        this.created = function () {
            _self = this;
            if(this.turno && this.contract){
              this.selectedShift = this.turno;
              this.selectedContract = this.contract;
              this.selectVehicle  = this.vehicle;
            }

            if(this.vehicle){
              this.selectedVehicle  = this.vehicle;
              this.selectedTray  = this.tray;
            }

            this.$store.dispatch('form/submit', true);
            this.getAllContracts();
            this.getVehicles();
            this.getAllUnloadZones();
        };

        this.mounted = function () {
            window.scrollTo(0, 0);
        };

        this.data = function () {
            return {
                var_gross: null,
                var_tara: null,
                var_meter_cubic: null,
                staticRoot: process.env.VUE_APP_STATIC,

                vehicles: [],


                newTrip: {
                  water_trip_id: null,
                  tara: '',
                  meter_cubic: '',
                  driver_water_trip: null,
                  vehicle_water_trip_id: null,
                  guia_number: '',
                  unload_zone_id: null,
                  closer_trip_username: null,
                  opener_trip_username: null,
                  work_shift_trip_id: null,
                  shift_contract_id: null,
                  tray_water_trip_id: null,
                  location_start_id: null,
                  location_finish_id: null,
                  location_return_id: null,
                  contract_rute_id: null,
                },

                
                openModalBrutoValida: false,
                openModalTaraValida: false,
                editting_trip: null,

                all_destination:[],
                all_returns:[],
                all_origins:[],
                all_rute:[],


                selectedDestination:null,
                selectedOrigin:null,
                selectedReturn:null,

                contracts:[],
                selectedContract: null,

                shifts:[],
                selectedShift: null,

                showAddShiftButton: false,
                showAddShiftImg: true,

                selectedVehicle: null,
                selectedTray: null,
                selectedRute: null,

                unloadZoneSelected: null,
                unloadZones: [],

                vuelta: null,

                guiaNumber: null,
                guiaDate: null,
                isManualGuide: false,
                
                tara: null,
                neto: null,
                bruto: null,


            }
        };
        this.components = {Stepper,  AutoComplete, TecladoNumerico, Dropdown, SelectedTurno};
        this.computed = {
           shouldHideDropdowns() {
                  return this.all_origins.length === 1 
                      && this.all_returns.length === 1 
                      && this.all_destination.length === 1 
                      && this.all_rute.length === 1;
              },
           formattedShifts() {
              return this.shifts.map(shift => ({
                ...shift,
                displayLabel: `${shift.work_shift_type}, ${this.formatDate(shift.work_shift_start)}`
              }));
            },
          ...mapGetters({
                username: 'auth/user',
                error: 'form/error-states',
                turno: 'turno/turno',
                contract: 'contract/contract',
                vehicle: 'vehicle/vehicle',
                tray: 'vehicle/tray',
            }),
        };
        this.methods = {
          updateNeto(value){
            this.neto = value;
          },
          updateBruto(value){
            this.bruto = value;
          },
          updateTara(value){
            this.tara = value;
          },
          updateGuiaNumber(value) {
            this.guiaNumber = value;
          },
          setDefaultValues() {
       
                    this.selectedOrigin = this.all_origins[0];
                    this.selectedReturn = this.all_returns[0];
                    this.selectedDestination = this.all_destination[0];
                    this.selectedRute = this.all_rute[0];
                
            },
          formatDate(dateString) {
            const date = new Date(dateString);
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Añadir 1 porque getMonth() devuelve un índice basado en cero
            const year = date.getFullYear();
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');


            return `${day}/${month}/${year} ${hours}:${minutes}`;
          },

           getWorkShiftRange2(contract){
            this.shifts = [];
            this.$store.dispatch('form/submit', true);
            var on_day_ago =  moment(new Date(), "YYYY-MM-DD").subtract(7, 'days').format("YYYY-MM-DD");
            var today =  moment(new Date(), "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD");
            var datesss = []
            datesss.push(on_day_ago);
            datesss.push(today);
            WorkShiftService.getWorkShiftRangeDates(contract.contract_id,datesss).then((response) => {
                    console.log(response.data);
                    this.shifts = response.data;

                    this.showAddShiftButton = true;
                    this.$store.dispatch('form/submit', false)
                
            }).catch((error) => {
                console.log(error);
                this.$store.dispatch('form/submit', false)
            });
          },
          replaceTZ(date){
            if(date){
              var stillUtc = moment.utc(date).toDate();
              var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm');
              return local;
            }
          },
            goToDetailStatus(id, status) {
              if(status === 'OPEN')
                this.$router.push({
                          name: 'shift-work-detail',
                          params: {
                              shift_work_id: id
                          }
                      });
            },
          getAllContracts(){
                this.$store.dispatch('form/submit', true);
             ContractService.getByEmployee(this.username).then((response) => {
                        this.contracts = response.data;
                        this.$store.dispatch('form/submit', false);
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
          },
          getLocationsByContract(id_contract){
            ContractService.getLocationsByContract(id_contract).then((response) => {
                console.log(response)
                 this.all_origins = response.data.locations.origins
                 this.all_returns = response.data.locations.returns
                 this.all_destination = response.data.locations.destinations
                 this.all_rute = response.data.locations.rutes
                 //this.setDefaultValues();
                 this.$store.dispatch('form/submit', false);
              }).catch((error) => {
                  console.log(error);
                  this.$store.dispatch('form/submit', false);
              });

          },
          getVariableContractCode(id_contract, code){
              ContractService.getVariableByContractCode(id_contract, code).then((response) => {
                if(response.data == null){
                    this.var_tara = {contract_variables: 1, contract_id: 2, code: "TARA", max_value: 17000, min_value: 14000};
                    this.var_meter_cubic = {contract_variables: 3, contract_id: 2, code: "METERCUBIC", max_value: 30000, min_value: 25000};
                    this.var_gross = {contract_variables: 2, contract_id: 2, code: "GROSS", max_value: 46000, min_value: 40000};
                }
                if(code === 'TARA')
                  this.var_tara = response.data;
                if(code === 'METERCUBIC')
                  this.var_meter_cubic = response.data;
                if(code === 'GROSS')
                  this.var_gross = response.data
                }).catch((error) => {
                    this.var_tara = {contract_variables: 1, contract_id: 2, code: "TARA", max_value: 17000, min_value: 14000};
                    this.var_meter_cubic = {contract_variables: 3, contract_id: 2, code: "METERCUBIC", max_value: 30000, min_value: 25000};
                    this.var_gross = {contract_variables: 2, contract_id: 2, code: "GROSS", max_value: 46000, min_value: 40000};
                  console.log(error);
              });
          },
          setBruto(event){
            this.bruto = event;
            this.validaBruto();
          },
          setTataNK(event){
            this.newTrip.tara = event;
            this.validarTara();
          },
          setNetoNK(event){
            this.newTrip.meter_cubic = event;
            this.validarNeto();
          },
          setGuiaNumer(event){
              this.newTrip.guia_number = event;
              this.guiaNumberValidation();
          },

          selectOtherVehicle: function () {
             this.$router.push({
                        name: 'vehicle-information',
                        params: {
                            shift_work_id: this.$route.params.shift_work_id,
                            contract_id: this.$route.params.contract_id,
                        }
                    });
          },
           getNumbersDots: function (num) {
              return num.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
            },


          // validaciones tara, bruto, neto, numGuia
           validarTara(){
             let result = {
                isError: false,
                message: null
            }
            if(this.newTrip.tara !== '')
              this.newTrip.tara = parseInt(this.newTrip.tara);
            else
              return;
            if(this.newTrip.tara !== null ){
              if(this.newTrip.tara < this.var_tara.min_value || this.newTrip.tara > this.var_tara.max_value ){
                  result.isError = true;
                  result.message = 'ERROR!, Tara debe ser menor a ' + this.var_tara.max_value +' kilos y mayor a ' + this.var_tara.min_value;
                  this.$store.dispatch('form/result', result);
                  this.openModalTaraValida = true;
                  return;
              }
            }
          },
          validaBruto(){
             let result = {
                isError: false,
                message: null
            }
            
            if(this.bruto !== ''){
              this.bruto = parseInt(this.bruto);
              if(this.bruto < this.var_gross.min_value || this.bruto > this.var_gross.max_value){
                  result.isError = true;
                  result.message = 'ERROR!, Bruto debe ser menor a ' + this.var_gross.max_value + ' kilos y mayor a' + this.var_gross.min_value;
                  this.$store.dispatch('form/result', result);
                   this.openModalBrutoValida = true;
                  return;  
              }

            }
           
          },
          validarNeto(){
            let result = {
                isError: false,
                message: null
            }
            
            if(this.newTrip.meter_cubic !== ''){
              this.newTrip.meter_cubic = parseInt(this.newTrip.meter_cubic);

              if(this.newTrip.meter_cubic < this.var_meter_cubic.min_value){
                  result.isError = true;
                  result.message = 'ERROR!, Neto debe ser mayor a '+ this.var_meter_cubic.min_value + ' kilos';
                  this.newTrip.meter_cubic = '';
                  this.$store.dispatch('form/result', result);
                  return;  
              }

              if(this.newTrip.meter_cubic < this.var_meter_cubic.max_value && this.newTrip.meter_cubic > this.var_meter_cubic.min_value ){
                  result.isError = false;
                  result.message = 'Neto bien ingresado';
                  this.$store.dispatch('form/result', result);
                  return;  
              }

               if(this.newTrip.meter_cubic > this.var_meter_cubic.max_value){
                  result.isError = true;
                  result.message = 'ERROR!, Neto debe ser menor a ' + this.var_meter_cubic.max_value +  ' kilos';
                  this.newTrip.meter_cubic = '';
                  this.$store.dispatch('form/result', result);
                  return;  
              }
              
            }
          },
          guiaNumberValidation(){
            let result = {
                isError: false,
                message: null
            }

            this.$store.dispatch('form/submit', true);
            if(this.newTrip.guia_number !== null){
              if(parseInt(this.newTrip.guia_number) <= 0){
                result.isError = true;
                    result.message = 'Numero debe ser mayor a 0';
                    this.newTrip.guia_number = null;
                      this.$store.dispatch('form/result', result);
                      this.$store.dispatch('form/submit', false);
                    return;
              }
            }
            if(this.newTrip.guia_number !== null){
              TripService.getTripByGuiaNumber(this.newTrip.guia_number).then((response) => {
                  if(response.data.length > 0){
                    result.isError = true;
                    result.message = 'Numero de guia ya asignado';
                    this.newTrip.guia_number = '';
                    this.$store.dispatch('form/result', result);
                    return;
                  }
                  this.$store.dispatch('form/submit', false);
              }).catch((error) => {
                  console.log(error);
                  this.$store.dispatch('form/submit', false);
              });
            }
              this.$store.dispatch('form/submit', false); 
          },
          getTrip(id){
            this.$store.dispatch('form/error', {
                  inputGuiaNumber: false,
                  inputMeterCubic: false,
                  inputTara: false,
                  inputOpenerTrip: false,
                  imputDriver:false,
                  inputVehicle: false,
                  inputWorkShift: false,
                  inputGuiaNumberFormat: false,
                  inputMeterCubicFormat: false,
                  inputTaraFormat: false,
                  inputBruto: false,
                  inputBrutoFormat: false,
                  inputTaraM3Bru: false,
                  inputMeterCubicRange: false,
              });
            TripService.get(id).then((response) => {
                    this.newTrip = response.data;
                    if(this.newTrip.guia_number === null)
                      this.newTrip.guia_number = '';
                    this.editting_trip = response.data;
                  this.$store.dispatch('form/submit', false);
              }).catch((error) => {
                  console.log(error);
                  this.$store.dispatch('form/submit', false);
              });
          },

            save(){
                this.$store.dispatch('form/reset');
                this.$store.dispatch('form/submit', true);
                

            let result = {
                isError: false,
                message: null
            }
               
               this.$store.dispatch('form/error', {
                  inputGuiaNumber: false,
                  inputMeterCubic: false,
                  inputTara: false,
                  inputOpenerTrip: false,
                  imputDriver:false,
                  inputVehicle: false,
                  inputWorkShift: false,
                  inputGuiaNumberFormat: false,
                  inputMeterCubicFormat: false,
                  inputTaraFormat: false,
                  inputBruto: false,
                  inputBrutoFormat: false,
                  inputTaraM3Bru: false,
                  inputMeterCubicRange: false,
              });

            if (this.username === null) {
                result.isError = true;
                result.message = 'Seleccione Conductor, reinicie aplicacion'
                _self.$store.dispatch('form/result', result);
                _self.$store.dispatch('form/submit', false);
                return;
            }

            if (this.selectedShift === null) {
                result.isError = true;
                result.message = 'Turno no seleccionado'
                _self.$store.dispatch('form/result', result);
                _self.$store.dispatch('form/submit', false);
                return;
            }

            this.selectedVehicle = this.vehicle
            if (this.selectedVehicle === null) {
                result.isError = true;
                result.message = 'Seleccione vehiculo'
                _self.$store.dispatch('form/result', result);
                _self.$store.dispatch('form/submit', false);
                return;
            }


            if (this.selectedOrigin === null) {
                result.isError = true;
                result.message = 'Seleccione origen del viaje'
                _self.$store.dispatch('form/result', result);
                _self.$store.dispatch('form/submit', false);
                return;
            }


            this.selectedRute = this.all_rute[0];
            if (this.selectedRute === null) {
                result.isError = true;
                result.message = 'Seleccione RUTA'
                _self.$store.dispatch('form/result', result);
                _self.$store.dispatch('form/submit', false);
                return;
            }
               //this.selectedDestination = this.selectedOrigin;
             if (this.selectedDestination === null) {
                result.isError = true;
                result.message = 'Seleccione destino del viaje'
                _self.$store.dispatch('form/result', result);
                _self.$store.dispatch('form/submit', false);
                return;
            }

            if(!this.isManualGuide){
               if (this.guiaNumber === null || this.guiaNumber.trim() === '') {
              result.isError = true;
                result.message = 'Complete Numero Guia'
                _self.$store.dispatch('form/result', result);
                _self.$store.dispatch('form/submit', false);
                _self.$store.dispatch('form/error', {inputGuiaNumber: true});
                return;
            }
            }
           

            if (this.neto === null) {
                result.isError = true;
                result.message = 'Complete Neto'
                _self.$store.dispatch('form/result', result);
                _self.$store.dispatch('form/submit', false);
                _self.$store.dispatch('form/error', {inputMeterCubic: true});
                return;
            }

          if (this.tara === null || this.tara === '') {
                result.isError = true;
                result.message = 'Complete Tara'
                _self.$store.dispatch('form/result', result);
                _self.$store.dispatch('form/submit', false);
                _self.$store.dispatch('form/error', {inputTara: true});
                return;
            }

            if (this.bruto === null) {
              result.isError = true;
                result.message = 'Complete Numero Bruto'
                _self.$store.dispatch('form/result', result);
                _self.$store.dispatch('form/submit', false);
                _self.$store.dispatch('form/error', {inputBruto: true});
                return;
            }


            if (this.tara !== null) {
                const reNumber = /^[1-9 ]+$/;
                if (reNumber.test(this.tara === false)) {
                  result.isError = true;
                  result.message = 'Tara debe ser numerica'
                  _self.$store.dispatch('form/result', result);
                  _self.$store.dispatch('form/submit', false);
                  _self.$store.dispatch('form/error', {inputTaraFormat: true});
                  return;
                }
            }


            if (this.bruto !== null) {
                const reNumber = /^[1-9 ]+$/;
                if (reNumber.test(this.tara === false)) {
                  result.isError = true;
                  result.message = 'Brutp debe ser numerica'
                  _self.$store.dispatch('form/result', result);
                  _self.$store.dispatch('form/submit', false);
                  _self.$store.dispatch('form/error', {inputBrutoFormat: true});
                  return;
                }
            }
          
            if (this.guiaNumber !== null) {
                const reNumber = /^[0-9 ]+$/;

                if (reNumber.test(this.guiaNumber.trim()) === false) {
                  result.isError = true;
                  result.message = 'Numero Guia debe ser numerico'
                  _self.$store.dispatch('form/result', result);
                  _self.$store.dispatch('form/submit', false);
                  _self.$store.dispatch('form/error', {inputGuiaNumberFormat: true});
                  return;
                }

                if(this.guiaDate == null){
                  result.message = 'Numero Guia sin fecha'
                  _self.$store.dispatch('form/result', result);
                  _self.$store.dispatch('form/submit', false);
                  _self.$store.dispatch('form/error', {inputGuiaNumberFormat: true});
                  return
                }

            }

            if (this.neto !== null) {
                const reNumber = /^[0-9 ]+$/;
                if (reNumber.test(this.neto === false)) {
                  
                  result.isError = true;
                  result.message = 'Neto debe ser numerico'
                  _self.$store.dispatch('form/result', result);
                  _self.$store.dispatch('form/submit', false);
                  _self.$store.dispatch('form/error', {inputMeterCubicFormat: true});
                  return;
                }
            }

          if(this.neto !== null && this.tara !== null && this.bruto !== null){
                var brutoTemp =  parseInt(this.tara) + parseInt(this.neto);
                if(brutoTemp != this.bruto){
                    
                  result.isError = true;
                  result.message = 'Error en bruto. Revisar valores Neto y Tara'
                  _self.$store.dispatch('form/result', result);
                  _self.$store.dispatch('form/submit', false);
                  _self.$store.dispatch('form/error', {inputMeterCubicRange: true});
                  return;
                }
            }

            
            this.newTrip.driver_water_trip = this.username;
            this.newTrip.opener_trip_username = this.username;
            this.newTrip.vehicle_water_trip_id = this.selectedVehicle.vehicle_id;

            this.selectedTray = this.tray;
            this.newTrip.tray_water_trip_id = this.selectedTray.vehicle_id;
            if(this.unloadZoneSelected != null)
              this.newTrip.unload_zone_id = this.unloadZoneSelected.unload_zone_id;
            else
               this.newTrip.unload_zone_id = null;
            this.newTrip.work_shift_trip_id = this.selectedShift.water_work_shift_id;

            this.newTrip.guia_number = this.guiaNumber;
            this.newTrip.guia_date = this.guiaDate;

            this.newTrip.meter_cubic = this.neto / 1000;

            this.newTrip.tara = this.tara;

            this.newTrip.location_start_id = this.selectedOrigin.contract_location
            this.newTrip.location_finish_id = this.selectedDestination.contract_location

            if (this.selectedReturn !== null ) {
              this.newTrip.location_return_id = this.selectedReturn.contract_location
            }else {
              this.newTrip.location_return_id = null
            }

             this.newTrip.contract_rute_id = this.selectedRute.contract_rute_id

            if(this.editting_trip === null){
                TripService.save(this.newTrip).then((response) => {
            
                        if(response.data.status == 'ERROR'){
                           result.isError = true
                          result.message = response.data.message;
                        }else{
                          result.isError = false
                          result.message = response.data.message;
                        }
                        _self.$store.dispatch('form/result', result);
                         this.$router.push({
                                              name: 'home',
                                              
                                          });
                        _self.$store.dispatch('form/submit', false)
                    }).catch((error) => {
                      this.newTrip.meter_cubic = this.newTrip.meter_cubic * 1000;
                        console.log(error);
                    }).finally(() => this.$store.dispatch('form/submit', false));
            }else{
                TripService.driverEditing(this.newTrip).then((response) => {
                        if(response.data.status == 'ERROR'){
                           result.isError = true
                          result.message = response.data.message;
                        }else{
                          result.isError = false
                          result.message = response.data.message;
                        }
                        _self.$store.dispatch('form/result', result);
                         this.$router.push({
                                              name: 'shift-work-detail',
                                              params: {
                                                  shift_work_id: this.$route.params.shift_work_id
                                              }
                                          });
                        _self.$store.dispatch('form/submit', false)
                    }).catch((error) => {
                      this.newTrip.meter_cubic = this.newTrip.meter_cubic * 1000;
                        console.log(error);
                    }).finally(() => this.$store.dispatch('form/submit', false));
            }
            
            },
            getAllUnloadZones(){
                 UnloadZoneService.getAll().then((response) => {
                        this.unloadZones = response.data;
               
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
            getVehicles(){
                 VehicleService.getAll().then((response) => {
                        this.vehicles = response.data;
      
                        this.$store.dispatch('form/submit', false);
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
            goTo: function (route) {
                this.$router.push({name: route});
            },
        };
        this.watch = {
          selectedVehicle(newValue){
            if (newValue) {
                this.$store.dispatch('vehicle/set-vehicle', newValue);
            }
          },
          selectedTray(newValue){
            if (newValue) {
                this.$store.dispatch('vehicle/set-tray', newValue);
            }
          },
          selectedContract(newValue, oldValue) {
            console.log(oldValue);
            if (newValue) {
              this.getWorkShiftRange2(newValue);
              this.newTrip.shift_contract_id = newValue.contract_id; 
              this.getVariableContractCode(newValue.contract_id, 'TARA');
              this.getVariableContractCode(newValue.contract_id, 'METERCUBIC');
              this.getVariableContractCode(newValue.contract_id, 'GROSS');
              this.getLocationsByContract(newValue.contract_id);
            }
          }
        };

          
        
    }).apply(NewTripage);

    export default NewTripage
</script>

<style scoped>
    .is-day{
        box-shadow: 0 0.5em 1em -0.125em rgba(251, 255, 73, 0.7), 0 0px 0 1px rgba(251, 255, 73, 0.1);
    }

    .is-night{
        box-shadow: 0 0.5em 1em -0.125em rgba(0, 0, 0, 0.7), 0 0px 0 1px rgba(0, 0, 0, 0.1);
    }

    .is-selected{
        box-shadow: 0 0.5em 1em -0.125em rgba(251, 255, 73, 0.7), 0 0px 0 1px rgba(251, 255, 73, 0.1);
    }

.p-fluid {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.text-center {
  text-align: center;
  font-size: 24px; /* Tamaño de fuente más grande para los títulos */
}

.dropdown, .inputtext, .button {
  font-size: 20px; /* Tamaño de fuente más grande para textos dentro de los inputs y botones */
  height: 50px; /* Altura mayor para inputs y botones */
  margin-bottom: 0.25rem; /* Más espacio entre elementos */
}

.inputtext {
  padding: 10px; /* Mayor padding dentro de los inputs para aumentar la legibilidad */
}

.button {
 padding: 0.5rem; /* Padding más grande para botones */
}

.dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 10px; /* Mayor padding para los items del dropdown */
  font-size: 18px; /* Tamaño de fuente más grande para los items dentro de los dropdowns */
}

.p-dropdown-label:not(.p-placeholder), .p-inputtext {
  font-size: 20px; /* Ajustar tamaño de fuente para los textos dentro de los dropdowns y inputs */
}

.p-dropdown .p-dropdown-panel {
  font-size: 18px; /* Tamaño de fuente para el panel de selección */
}

.flex-container {
  display: flex;
  align-items: center;
}

.flex-item-grow {
  flex-grow: 1;
}

.p-button-help {
  padding: 0.5rem; /* Reducir el padding para que el botón no sea muy grande */
  width: 2rem; /* Ajustar el ancho mínimo para que el botón sea más pequeño */
}

.error-border {
  border: 1px solid red;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}


</style>
