<template>
  <div class="input-keyboard">
    <div class="flex flex-column full-width">
      <div class="flex align-items-center flex-grow-1">
        <div class="fake-input" @click.prevent="toggleHiddenOptions" :class="{'is-error-state': KBerrorState}">
          <div class="label-fake-input" v-if="kbValue === ''">
            {{ placeholderFakeField }}
          </div>
          <div v-else class="label-fake-input">
            <span v-if="getKeyBoardValue !== null">{{ getNumbersDots(getKeyBoardValue) }}</span>
          </div>
        </div>
      </div>
    </div>

    <DialogPrime
      v-model:visible="showkeybooard"
      :modal="true"
      :closable="true"
      :style="{ width: '100vw', height: '100vh', margin: '0', padding: '0' }"
      :header="titleKeyBoard"
      @hide="onHide"
    >
      <div class="flex flex-column">
        <div class="flex justify-content-center mb-4">
          <div class="fake-input" :class="{'is-error-state': KBerrorState}">
            <div class="label-fake-input">
              <span v-if="getKeyBoardValue !== null">{{ getNumbersDots(getKeyBoardValue) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-nogutter keyboard-grid">
        <div class="col-4" v-for="num in [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]" :key="`num-${num}`">
          <ButtonPrime 
            :label="num.toString()" 
            @click.prevent="write(num)" 
            class="button p-button-rounded p-button-fullwidth" 
          />
        </div>
        <div class="col-4">
            <ButtonPrime icon="pi pi-delete-left" @click.prevent="deleteCaracter" class="button p-button-rounded p-button-fullwidth"  />
        </div>
        <div class="col-4">
          <ButtonPrime label="" icon="pi pi-check" @click.prevent="ready" class="button p-button-rounded p-button-fullwidth"  />
        </div>
      </div>
    </DialogPrime>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      showkeybooard: false,
      kbValue: '',
      KBerrorState: false,
    };
  },
  computed: {
    getKeyBoardValue() {
      return this.kbValue;
    },
    getShowkeybooard() {
      return this.showkeybooard;
    },
  },
  methods: {
     onHide() {
      console.log("aqui")
  },
  ready() {
    if(!this.kbValue)
      return
    this.showkeybooard = false;
    this.$emit('readyFromKeyboard', this.kbValue);
  },
  deleteCaracter() {
    if(!this.kbValue)
      return
    this.kbValue = this.kbValue.toString().substring(0, this.kbValue.length - 1);
  },
  write(v) {

    if (v !== null && v !== undefined) {  // Verificación adicional
      v = v.toString();
      if(this.kbValue == null)
        this.kbValue = '';
      this.kbValue = this.kbValue.toString() + v;
    } else {
      console.error('El valor proporcionado a write es nulo o indefinido:', v);
    }
  },
  toggleHiddenOptions() {
    this.kbValue = this.keyboardValue;
    this.showkeybooard = !this.showkeybooard;
  },
  getNumbersDots(num) {
    if (num !== null && num !== undefined) {
      return num.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');
    }
    return '';
  },
},

  watch: {
    keyboardValue(newValue) {
      this.kbValue = newValue;
    },
    errorState(newValue) {
      this.KBerrorState = newValue;
    },
  },
  props: {
    titleKeyBoard: String,
    errorState: Boolean,
    keyboardValue: String,
    placeholderFakeField: String,
  },
});
</script>

<style scoped>
.fake-input {
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
  max-width: 100% !important;
  width: 100% !important;
  height: 50px;
  background-color: white !important;
  font-size: 1rem;
  color: #495057;
  padding: 0.5rem 0.5rem;
  border: 1px solid #ced4da;
}

.label-fake-input {
  padding: 3px !important;
}

.is-error-state {
  border-color: red !important;
}

.keyboard-grid .col-4 {
  padding: 0.5rem;
}

.button {
  width: 100%;
  height: 60px; /* Ajusta la altura de los botones si es necesario */
  font-size: 1.2rem; /* Ajusta el tamaño del texto de los botones */
}
</style>
