import axios from "axios";

export default {

    uploadDriverDocument(formData ){
        console.log(formData)
    },

    getWorkShiftRange(delta_days, contract_id){
        const url = `${process.env.VUE_APP_API_URL}/work_shifts`;
        let data = {operation: 'get-work-shift-range',
                    delta_days: delta_days,
                    contract_id: contract_id,
                    }
        return axios.post(url, data)
    },

    setDriverLog(log){
        const url = `${process.env.VUE_APP_API_URL}/work_shifts`;
        let data = {operation: 'set-work-shift-driver-log',
                    log: log,
                    }
        return axios.post(url, data)
    },

    getWorkShiftRangeDates(contract_id, dates){
        const url = `${process.env.VUE_APP_API_URL}/work_shifts`;
        let data = {operation: 'get-work-shift-range-dates',
                    dates: dates,
                    contract_id: contract_id,
                    }
        return axios.post(url, data)
    },
    getAll() {
        const url = `${process.env.VUE_APP_API_URL}/work_shifts`;
        return axios.get(url);
    },
    get(id) {
        const url = `${process.env.VUE_APP_API_URL}/work_shift`;
        return axios.get(url, id)
    },
    save(trip) {
        const url = `${process.env.VUE_APP_API_URL}/work_shift`;
        return axios.post(url, trip)
    },

    ihaveShiftOpen(employee_id) {
        const url = `${process.env.VUE_APP_API_URL}/work_shifts`;
        let data = {operation: 'i-have-shift-open', employee_id: employee_id}
        return axios.post(url, data)
    },

    openShift(s, employee_id){
        const url = `${process.env.VUE_APP_API_URL}/work_shifts`;
        let data = {operation: 'open-shift-employee',
                    work_shift_id: s.water_work_shift_id,
                    employee_id: employee_id
                    }
        return axios.post(url, data)
    },

    getDriversWorkShift(s){
        const url = `${process.env.VUE_APP_API_URL}/work_shifts`;
        let data = {operation: 'get-driver-work-shift',
                    work_shift_id: s
                    }
        return axios.post(url, data)
    },

    getVehicleWorkShift(s){
        const url = `${process.env.VUE_APP_API_URL}/work_shifts`;
        let data = {operation: 'get-vehicle-work-shift',
                    work_shift_id: s
                    }
        return axios.post(url, data)
    },

    closeShift(s, employee_id, total_vehicle_used){
        const url = `${process.env.VUE_APP_API_URL}/work_shifts`;
        let data = {operation: 'close-shift',
                    water_work_shift_id: s.water_work_shift_id,
                    employee_id: employee_id,
                    total_vehicle_used: total_vehicle_used
                    }
        return axios.post(url, data)
    },

    newMultiple(object){
        const url = `${process.env.VUE_APP_API_URL}/work_shifts`;
        let data = {operation: 'new-multiple-shift',
                    object: object
                    }
        return axios.post(url, data)
    },

    getWorkShift(id) {
        const url = `${process.env.VUE_APP_API_URL}/work_shifts`;
        let data = {operation: 'get-shift-id', id: id}
        return axios.post(url, data)
    },

    getWorkShiftByContract(id) {
        const url = `${process.env.VUE_APP_API_URL}/work_shifts`;
        let data = {operation: 'get-by-contract', contract_id: id}
        return axios.post(url, data)
    },

    getCloseWorkShiftByContract(contract_id) {
        const url = `${process.env.VUE_APP_API_URL}/work_shifts`;
        let data = {operation: 'get-close-shift-by-contract', contract_id: contract_id}
        return axios.post(url, data)
    },

    getTotalWater(id){
        const url = `${process.env.VUE_APP_API_URL}/work_shifts`;
        let data = {operation: 'get-total-water', id: id}
        return axios.post(url, data)
    },

     getOpenShiftEmployee(employee_id) {
        const url = `${process.env.VUE_APP_API_URL}/work_shifts`;
        let data = {operation: 'get-open-shift-employee', employee_id: employee_id}
        return axios.post(url, data)
    },

    getOpenShiftResume(contract_id) {
        const url = `${process.env.VUE_APP_API_URL}/work_shifts`;
        let data = {operation: 'get-open-shift-resumen', contract_id: contract_id}
        return axios.post(url, data)
    },

    calculatingTotalWater(contract_id, month) {
        const url = `${process.env.VUE_APP_API_URL}/work_shifts`;
        let data = {operation: 'calculating-total-water-full', 
                        month: month,
                        contract_id: contract_id,
                    }
        return axios.post(url, data)
    },


    



}
