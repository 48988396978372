<template>
  <div class="container">
    <div v-if="!isCropping">
      <label class="file-label">
        Adjuntar/Tomar imagen
        <input type="file" capture="camera" @change="onFileChange" accept="image/*">
        <span class="icon">
          <i v-if="imageSrc" class="pi pi-image"></i>
          <i v-else class="pi pi-plus"></i>
        </span>
      </label>
      <ButtonPrime  class="p-button-raised p-button-rounded large-button" @click="submitImage">Nuevo Viaje</ButtonPrime>
    </div>

    <div v-else>
      <vue-cropper
        ref="cropper"
        :src="imageSrc"
        :options="cropperOptions"
      ></vue-cropper>
      <ButtonPrime  class="p-button-raised p-button-rounded large-button" @click="cropImage">Recortar</ButtonPrime>
    </div>
  </div>
</template>


<style>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.file-label {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 20px;
  padding: 10px 20px;
  margin: 10px;
  transition: background-color 0.3s;
  overflow: hidden;
}

.file-label:hover {
  background-color: #e5e5e5;
}

.file-label input[type="file"] {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.icon {
  margin-left: 10px;
  font-size: 18px;
}

.submit-button {
  margin-top: 20px;
  width: 100%;
}
</style>


<script>
import 'cropperjs/dist/cropper.css';
import VueCropper from 'vue-cropperjs';
import axios from "axios";

export default {
  name: "ScannerTicket",
  components: {
    VueCropper,
  },
  props: {
    fields: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
        isCropping: false,
      imageSrc: null,
      imageString: null,
      response: null,
      cropperOptions: {
        aspectRatio: 16/9,
      },
    };
  },
  methods: {
     onFileChange(e) {
    const file = e.target.files[0];
    this.imageSrc = URL.createObjectURL(file);
    this.isCropping = true; // Mostrar la sección de recorte
  },
  cropImage() {
    const croppedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
    this.imageString = croppedImage.split(",")[1];
    this.isCropping = false; // Ocultar la sección de recorte y mostrar la sección de selección de archivo
  },
    async submitImage() {
      try {
        const payload = {
          image_string: this.imageString,
          fields: JSON.stringify(this.fields),
        };
        const response = await axios.post(process.env.VUE_APP_API_URL+'/trip-ticket', payload, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        this.response = response.data;
        this.$emit('imageUploaded', this.response); // Emitir el evento con la respuesta
      } catch (error) {
        console.error("Error al enviar la imagen:", error);
      }
    },
  },
};
</script>
