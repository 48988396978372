import { createApp } from 'vue'
import Appp from './App.vue';
import router from './router';
import store from './store/index';
import Axios from 'axios';

import Toolbar from 'primevue/toolbar';
import Button from 'primevue/button';
import SplitButton from 'primevue/splitbutton';
import PrimeVue from 'primevue/config';
import Sidebar from 'primevue/sidebar';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password'; 
import Menu from 'primevue/menu';
import Dropdown from 'primevue/dropdown';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputSwitch from 'primevue/inputswitch';
import FileUpload from 'primevue/fileupload';
import CardPrime from 'primevue/card';

import ProgressSpinner from 'primevue/progressspinner';
import Message from 'primevue/message';
import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Dialog from 'primevue/dialog';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import Textarea from 'primevue/textarea';
import Tooltip from 'primevue/tooltip';
import Tag from 'primevue/tag';
import ProgressBar from 'primevue/progressbar';
import Checkbox from 'primevue/checkbox';
import CalendarPrime from 'primevue/calendar';


import "primevue/resources/themes/saga-orange/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import { checkAuthentication } from './auth'

const app = createApp(Appp).use(router);
app.use(PrimeVue);
app.use(store);
app.use(ConfirmationService);

app.component('ToolBar', Toolbar);
app.component('ButtonPrime', Button);
app.component('SplitButtonp', SplitButton);
app.component('SidebarPrime', Sidebar);
app.component('InputText', InputText);
app.component('PasswordPrime', Password);
app.component('MenuPrime', Menu);
app.component('DropdownPrime', Dropdown);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('DataTable', DataTable);
app.component('ColumnPrime', Column);
app.component('InputSwitch', InputSwitch);
app.component('FileUpload', FileUpload);
app.component('ProgressSpinner', ProgressSpinner);
app.component('MessagePrime', Message);
app.component('AutoComplete', AutoComplete);
app.component('AccordionPrime', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('DialogPrime', Dialog);
app.component('ConfirmDialog', ConfirmDialog);
app.component('TextareaPrime', Textarea);
app.component('TagPrime', Tag);
app.component('ProgressBar', ProgressBar);
app.component('CheckBox', Checkbox);
app.component('CardPrime', CardPrime);
app.component('CalendarPrime', CalendarPrime);


app.directive('tooltip', Tooltip);

router.isReady().then(() => {
  app.mount('#app');
});


router.beforeResolve(async (to, from, next) => {
  store.dispatch('form/submit', true)
  try {
    await checkAuthentication(to)
    next()
  } catch (error) {
    console.log(error)
    next({ name: 'login' })
  } finally {
    store.dispatch('form/submit', false)
  }
})

Axios.interceptors.request.use(function (config) {	

  if (config.method !== 'options' && store.getters['auth/access_token']) {
		config.headers.Authorization = `Bearer ${store.getters['auth/access_token']}`;
		//config.headers.uuid = window.device.uuid;
		config.headers.employee = store.getters['auth/user'];
  }
  return config
}, function (error) {
  console(error.reponse.data);
});



