<template>
  <div class="answers">
    <button v-for="n in 4" :key="n" @click="emitAnswer(n)" :class="['boton-rango', { 'is-selected': selectedAnswer === n }]">{{ n }}</button>
  </div>
</template>

<script>
export default {
  props: {
    initialAnswer: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      selectedAnswer: this.initialAnswer
    };
  },
  methods: {
    emitAnswer(answer) {
      this.selectedAnswer = answer;
      this.$emit('answerSelected', answer);
    }
  }
};
</script>

<style scoped>
.answers {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.answers button {
  margin: 5px;
  padding: 15px 30px;
  font-size: 18px;
  width: 100px;
}

.boton-rango {
  background-color: white;
  border-color: blue;
  color: black;
}

.is-selected {
  background-color: rgb(131, 131, 254) !important;
  color: black !important;
}
</style>
