import axios from "axios";

export default {
    getAll() {
        const url = `${process.env.VUE_APP_API_CORE_URL}/vehicles`;
        return axios.get(url);
    },

    getAllTray() {
        const url = `${process.env.VUE_APP_API_CORE_URL}/tray`;
        return axios.get(url);
    },

    get(id) {
        const url = `${process.env.VUE_APP_API_CORE_URL}/vehicle`;
        return axios.get(url, id)
    },

    get_by_id(id) {
        const url = `${process.env.VUE_APP_API_CORE_URL}/vehicle`;
        let data = {vehicle_id: id}
        return axios.post(url, data)
    },

    useVehicle(vehicle, kilometers, tray) {
        const url = `${process.env.VUE_APP_API_CORE_URL}/vehicles`;
        let data = {operation: 'use-vehicle', vehicle: vehicle, kilometers: kilometers, tray: tray}
        return axios.post(url, data)
    },


    newKilometers(vehicle, kilometers) {
        const url = `${process.env.VUE_APP_API_CORE_URL}/vehicles`;
        let data = {operation: 'update-vehicle-kilometers', vehicle: vehicle, kilometers: kilometers}
        return axios.post(url, data)
    },


}
