import axios from "axios";

export default {
    getAll() {
        const url = `${process.env.VUE_APP_API_URL}/unload_zone`;
        return axios.get(url);
    },
    get(id) {
        const url = `${process.env.VUE_APP_API_URL}/unload_zone`;
        return axios.get(url, id)
    },
}
