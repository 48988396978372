<template>
  <div class="fluid-container">
    <h2>Viajes Conductor Turno
	<ButtonPrime label="+" @click="goToNew()" class="p-button-raised p-button-rounded large-button"></ButtonPrime>
    </h2>

     <SelectedTurno v-if="turno && contract" :numero_viajes="trips.length" :conductor="'Amigo'"></SelectedTurno>


    <div v-if="loading" class="text-center">
      Cargando viajes...
    </div>
    <div v-if="!loading && trips.length === 0" class="text-center">
      No se encontraron viajes.
    </div>
    <ul class="trip-list">
      <li v-for="trip in trips" :key="trip.water_trip_id" :class="cardClass(trip.trip_status)">
        <h3>Viaje ID: {{ trip.water_trip_id }} ({{ translateStatus(trip.trip_status) }} )</h3>
        <!--p><strong>Estado:</strong> {{ translateStatus(trip.trip_status) }}</p-->
        <p><strong>Guía Número:</strong> {{ trip.guia_number || 'No asignado' }}</p>
        <p><strong>Inicio:</strong> {{ formatDate(trip.trip_start) }}</p>
        <p><strong>Fin:</strong> {{ formatDate(trip.trip_finish) }}</p>
        <p v-if="getDuration(trip.trip_start, trip.trip_finish) >0"><strong>Duración:</strong>{{ getDuration(trip.trip_start, trip.trip_finish)}}</p>



        <button v-if="trip.trip_status == 'OPEN' && confirmCloseTrip === false" @click="confirmCloseTrip = true;" class="button success">Cerrar Viaje</button>

        <div v-if="trip.trip_status === 'OPEN' && confirmCloseTrip === true && unloadZones.length > 0">
          <div class="box is-info">
            <p class="has-text-dark">Seleccione zona descarga para confirmar</p>
              <div v-for="(z, idx) in getZonasDescarga" :key="idx">
                  <button class="button success" @click.prevent="closeTrip(trip,z)">{{z.name}}</button>
              </div>
          </div>
        </div>


        <!--button @click="attachGuide(trip)" class="button success">Adjuntar Guía</button>
        <button @click="attachWeightTicket(trip)" class="button info">Adjuntar Ticket de Pesaje</button-->
      </li>
    </ul>
  </div>
</template>

<script>
    import {mapGetters} from 'vuex';
import TripService from '@/services/TripService'; // Asegúrate de que la ruta es correcta
import SelectedTurno from '@/components/SelectedTurno';
    import UnloadZoneService from '@/services/UnloadZoneService';

export default {
  data() {
    return {
      loading: false,
      trips: [],
      unloadZones: [],
      confirmCloseTrip: false,
       result: {
                    isError: false,
                    message: null
                },

    };
  },
  created() {
    this.fetchTrips();
  },
  methods: {
        getAllUnloadZones(){
              this.$store.dispatch('form/submit', true);
                 UnloadZoneService.getAll().then((response) => {
                        this.unloadZones = response.data;
                            this.$store.dispatch('form/submit', false);
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },

     closeTrip(trip,zone){
              this.$store.dispatch('form/submit', true);
              TripService.closeTrip(trip, this.username, zone, this.turno.water_work_shift_id)
                        .then((response) => {
                            if (response.data !== true){
                              this.result.message = 'Viaje cerrado.';
                            }
                            else{
                              this.result.isError = true;
                              this.result.message = 'No pudo cerrar viaje.'
                            }
                             this.fetchTrips();
                            this.$store.dispatch('form/result', this.result);
                            this.$store.dispatch('form/submit', false);
                        }).catch((error) => {
                            this.result.isError = true;
                            if (error.data) {
                                this.result.message = error.data.message;
                            } else {
                                this.result.message = 'Ha ocurrido un error interno(closing shift)'
                            }
                            this.$store.dispatch('form/result', this.result);
                            this.$store.dispatch('form/submit', false);
                        })
            },

	goToNew(){
		this.$router.push({ name: 'new-trip' });
	},
    fetchTrips() {
      this.loading = true;

        TripService.getByWorkShiftDriver(this.turno.water_work_shift_id, this.username).then((response) => {
                        this.trips = response.data;
                        this.getAllUnloadZones();
                    }).catch(error => {
          console.error('Error al obtener los viajes:', error);
        })
        .finally(() => {
          this.loading = false;
        });
            },

      /*TripService.getByDriver(this.username) // Asumimos que el ID del conductor se proporciona aquí
        .then(response => {
          this.trips = response.data;
        })
        .catch(error => {
          console.error('Error al obtener los viajes:', error);
        })
        .finally(() => {
          this.loading = false;
        }); */


    attachGuide(trip) {
      console.log('Adjuntar guía para el viaje:', trip.water_trip_id);
    },
    attachWeightTicket(trip) {
      console.log('Adjuntar ticket de pesaje para el viaje:', trip.water_trip_id);
    },
    formatDate(date) {
      if (!date) return 'No disponible'; // Manejo de fechas nulas
      return new Date(date).toLocaleString('es', { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit', 
        hour: '2-digit', 
        minute: '2-digit', 
        second: '2-digit' 
      });
    },
    getDuration(start, end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const diff = endDate - startDate;
      return Math.floor(diff / 60000) + ' minutos'; // Conversión de milisegundos a minutos
    },
    cardClass(status) {
      if (status === 'OPEN') return 'trip-card open';
      if (status === 'CLOSE') return 'trip-card close';
      return 'trip-card default';
    },
    translateStatus(status) {
      const statusTranslations = {
        'OPEN': 'Abierto',
        'CLOSE': 'Cerrado',
        'DEFAULT': 'Por defecto' // Asume que existe un estado 'DEFAULT'
      };
      return statusTranslations[status] || status; // Retorna el estado traducido o el original si no está definido
    }
  },
  components: {SelectedTurno},
  computed: {
    
    getZonasDescarga(){
      const zonasNoMostrar = ["Piscina 3800", "Cancha 1", "Dren 4", "Relevadora"];
      return this.unloadZones.filter(z => !zonasNoMostrar.includes(z.name));
    },
     ...mapGetters({
                username: 'auth/user',
                error: 'form/error-states',
                turno: 'turno/turno',
                contract: 'contract/contract',
                vehicle: 'vehicle/vehicle',
                remolque: 'remolque/remolque',
            }),


        }
};

</script>

<style scoped>

.fluid-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.text-center {
  text-align: center;
}

.trip-list {
  list-style: none;
  padding: 0;
}

.trip-card {
  background-color: #f3f4f6;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 8px;
}

.trip-card.open {
  border: 2px solid red;
}

.trip-card.close {
  border: 2px solid green;
}

.trip-card.default {
  border: 2px solid gray;
}

.button {
  margin-top: 10px;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
}

.success {
  background-color: #28a745;
}

.info {
  background-color: #17a2b8;
}
</style>