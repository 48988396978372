const state = () => ({
	remolque: localStorage.getItem('remolque'),
});

const getters = {
	remolque(state) {
        return state.remolque;
    },
};

const actions = {
	'set-remolque'(context, remolque) {
        context.commit('updateRemolque', remolque);
        localStorage.setItem('remolque', remolque);
    },
};

const mutations = {
	updateRemolque(state, value) {
		state.remolque = value;
	},
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
