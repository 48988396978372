<template>
    <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="has-text-centered">
            <div class="grid">
                <div class="col-2">
                    <span v-if="!isDisablingBottom">
                        <ButtonPrime class="p-button-secondary p-button-small" icon="pi pi-arrow-left" v-if="$route.name !== 'home'" @click.prevent="goBack()" />
                    </span>
                    <span v-else>
                        <ButtonPrime class="p-button-secondary p-button-small" icon="pi pi-arrow-left" v-if="$route.name !== 'home'" @click.prevent="goBack()" disabled />
                    </span>
                </div>
                <div class="col-8">
                    <ButtonPrime class="p-button-secondary p-button-small" v-if="$route.name !== 'home'" icon="pi pi-home" @click.prevent="goTo('home')" />
                    <div class="footer-text">
                        &copy;{{ new Date().getFullYear() }} - <strong>Transportes Ventanas</strong>
                    </div>
                </div>
                <div class="col-2">
                    <ButtonPrime class="p-button-secondary p-button-small" icon="pi pi-arrow-up" v-if="$route.name !== 'home'" @click.prevent="scrollUp()" />
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: "BottomBar",

    data() {
        return {
            disablingButton: false
        }
    },

    computed: {
        isDisablingBottom() {
            return this.disablingButton;
        }
    },

    methods: {
        goBack() {
            var b = this;
            b.disablingButton = true;
            window.setTimeout(
                function() {
                    b.disablingButton = false;
                }, 2000);
            this.$router.go(-1)
        },
        goTo(route) {
            this.$router.push({ name: route });
        },
        scrollUp() {
            window.scrollTo(0, 0);
        }
    }
}
</script>

<style scoped>
.navbar {
    padding: 0.5rem 0;
    height: 50px;
    background-color: black;
    color: white;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 0.75rem;
}

.footer-text {
    font-size: 0.75rem;
}

.p-button-small {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
}
</style>
