<template>
  <div class="d-flex flex-column ai-center jc-center" style="height: 100vh;">
    <img alt="Vue logo" src="../assets/logo.svg" class="logo">
    <h1 class="title">Bienvenido</h1>
    <h2 class="title">Aplicación Conductor</h2>
    <ButtonPrime 
      v-if="!loading" 
      label="Ir" 
      @click="goToLogin" 
      class="p-button-raised p-button-rounded large-button"
    ></ButtonPrime>
    <p v-if="loading">Validando token...</p>
    <p v-if="error" class="error-message">Token inválido. Redirigiendo a inicio de sesión...</p>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService';  // Importa AuthService

export default {
  name: 'IntroPage',
  data() {
    return {
      loading: false,
      error: false,
    };
  },
  methods: {
    // Método para validar el token y navegar a la pantalla adecuada
    async validateToken(token) {
      this.loading = true;
      try {
        // Llamada al servicio AuthService para refrescar el token
        alert(token)
        const response = await AuthService.refresh_access(token);
        alert(response)
        // Suponiendo que la respuesta contiene el nuevo access_token y refresh_token
        const { access_token, refresh_token, user, groups } = response.data;

        // Guardar los tokens y detalles del usuario en el store de Vuex
        this.$store.dispatch('auth/set-access-token', access_token);
        this.$store.dispatch('auth/set-refresh-token', refresh_token);
        this.$store.dispatch('auth/set-user', user);
        this.$store.dispatch('auth/set-groups', groups);

        // Redirigir a la vista de selección de turno
        this.$router.replace({ name: 'select-turno' });
      } catch (err) {
        console.error('Error validando el token:', err);
        this.error = true;

        // Redirigir al login después de mostrar el error
        setTimeout(() => {
          this.$router.push('/login');
        }, 3000);  // Redirigir al login después de 3 segundos
      } finally {
        this.loading = false;
      }
    },
    goToLogin() {
      this.$router.push('/login');
    }
  },
  mounted() {
    // Capturar el token de los parámetros de la URL
    const token = this.$route.query['sso-token'];

    if (token) {
      // Si el token está presente, comienza el proceso de validación
      this.validateToken(token);
    } else {
      // Si no hay token, redirigir inmediatamente al login
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
.logo {
  margin-top: 2rem;
  width: 40%;
  max-width: 150px;
  margin-bottom: 2rem; 
}

.title {
  margin-bottom: 1rem;
  color: #2c3e50;
}

.error-message {
  color: red;
}

.large-button {
  font-size: 1.5rem;
  padding: 1rem 2rem;
}
</style>
