<template>
    <div class="stepper">
        <div class="tabs is-toggle is-fullwidth">
            <ul>
                <li v-for="(s, idx) in stepsData" :key="idx"
                    :class="{ 'is-active': index === s.position }">
                    <a>
                        <span>{{s.tittle}}</span>
                    </a>
                </li>
            </ul>
        </div>


        <div v-for="n in steps" :key="n" class="step-container">
            <div v-if="n === index" class="step-content">
                <div class="buttons" v-if="haveControlupper === true">
                    <button v-if="n > 1" class="button is-dark is-outlined" @click.prevent="back">Anterior</button>
                    <button v-if="n < steps" class="button is-dark" @click.prevent="next">Siguiente</button>
                    <button v-if="n === steps" class="button is-warning" @click.prevent="finish">Finalizar
                    </button>
                </div>
                <slot :name="n"></slot>
                <div class="buttons" v-if="haveControlupper === true">
                    <button v-if="n > 1" class="button is-dark is-outlined" @click.prevent="back">Anterior</button>
                    <button v-if="n < steps" class="button is-dark" @click.prevent="next">Siguiente</button>
                    <button v-if="n === steps" class="button is-warning" @click.prevent="finish">Finalizar
                    </button>
                </div>
            </div>
        </div>


    </div>
</template>

<style scoped>

    .transport-water {
        .tabs {
            li {
                border: 1px solid white;
                margin: 0 0.4rem;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }

                &.is-active {
                    a {
                        border-color: back;
                        background-color: black;
                        color: white;
                        border-radius: 0;
                    }
                }

                a {
                    background-color: white;
                    color: black;
                }
            }
        }

        .step-content {
            background-color: smokewhite;
            padding: 0.8rem;
            
        }


    .step-content{
        border: 0px solid lightGrey !important; 
    }

    }
</style>

<script>
    let Component = {};
    (function () {
        this.data = () => ({
            index: 1,
        });

        this.created = function () {
            if (this.step) this.index = this.step;
        };

        this.methods = {
            back: function () {
                if (this.index === 1) return;
                this.index--;
            },
            next: function () {
                if (this.index === this.steps) return;
                this.index++;
            },
            finish: function () {
                this.$emit("finish", {});
            }
        };

        this.watch = {
            step: function (newValue) {
                this.index = newValue
            },
            index: function (newValue) {
                this.$emit('stepChanged', newValue)
            }
        };

        this.props = [
            'steps',
            'stepsData',
            'step',
            'haveControlupper',
            'haveControlBottom',
        ];

    }).apply(Component);
    export default Component;
</script>
